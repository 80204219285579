import styled from 'styled-components';
import colors from '~/styles/colors';


export const TextField = styled.input`
    width: 40px;
    border-radius: 7px;

    padding: 10px;
    flex: auto;
    &:focus {
      outline: none;
    }
    border: none !important;
    box-sizing: border-box;
    background-color: transparent;
    text-align: center;
    font-size: 20px;
`;

export const TokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    input {
    -webkit-text-security: disc;
    }
    input[type='number'] {
        -moz-appearance:textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;

export interface TextFieldProps {
    icon?: boolean;
    error?: boolean;
    check?: boolean;
    disabled?: boolean;
}

export const TextFieldContainerInput = styled.div<TextFieldProps>`
    display: flex;
    min-height: 60px;
    min-width: 60px;
    font-family: ${props => props.theme.text.font.primary};
    box-sizing: border-box;
    background-color: ${props =>props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
    border-radius: 22px;

    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${colors[props.error ? 'red' : props.check ? 'green' : 'grey70']};
    `};
`;
