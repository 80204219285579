import styled, { css } from 'styled-components';

export interface CardMediaContainerProps {
  height?: string;
  minHeight?: string;
  maxwidth?: string;
  alignItems?: string;
  borderRadius?: string;
  pointer?: boolean;
  disabled?: boolean
}

export const CardMediaContainer = styled.div<CardMediaContainerProps>`
    overflow: hidden;
    height: ${props =>props.height};
    cursor: ${props =>props.pointer ? 'pointer' : null};
    border-radius: ${props =>props.borderRadius};
    min-height: ${props =>props.minHeight};
    width: 100%;
    max-width: ${props =>props.maxwidth};
    display: flex;
    box-sizing: border-box;
    position: relative;
    align-items: ${props => props.alignItems || 'center'};
    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
    .img {
        width: 100%;
        ${props => props.disabled ? css`
        opacity: 0.3;
        filter: grayscale(100%);
        ` : null}
    }
    .noimage {
      width: 24px !important;
      border-radius: 0px !important;
      box-shadow:none !important;
    }
    .skeleton {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f0f0f0 18%, #ececec 33%);
      background-size: 200% 100%;
      animation: 1s shine linear infinite;
    }

    .errorContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${props => props.theme.colors.white};
      display: flex;
      justify-content: center;
      align-items: center;
    }

`;
