import React from 'react';
import { EditIcon, IconCircleAlert, LocationOnIcon, ScheduleIcon, StoreIcon } from '~/assets/icons';
import { Button, CustomCarrousel, Modal, Title, Typography, Tag, IconButton } from '~/components';
import Grid from '~/components/Grid';
import { Colonias, DataCarritoTypes, DetalleEnvioType, DireccionesTypes, DireccionType, ErrorTextFieldType, SelectItemsTypes } from '~/interfaces';
import { getUtcDate } from '~/utils';
import NuevaDireccion from '~/views/misDirecciones/containers/nuevaDireccion';
import ProgramarEnvio from './containers/ProgramarEnvio';
import Recoleccion from './containers/Recoleccion';
import SeleccionarDireccion from './containers/SeleccionarDireccion';
import { OptionContainer, ShowContainer } from './styled';
type errorNumeroExt = {
    error: boolean;
    check: boolean;
    helperText: string;
}
type Props = {
	open: boolean,
	handleClose: () => void,
	activePosition: number,
	isCheckout?: boolean,
	handleAddDireccion: () => void,
	tipoStep: number,
	getFunctionButton: () => (() => void) | undefined,
	nuevaDireccion: {
		codigoPostal: string,
		handleDataColonias: () => void,
		handleCodigoPostal: (e: React.ChangeEvent<HTMLInputElement>) => void,
		colonia: SelectItemsTypes,
		handleColonia: (e: SelectItemsTypes) => void,
		dataColonias: Colonias | undefined,
		calle: string,
		handleCalle: (e: React.ChangeEvent<HTMLInputElement>) => void,
		numeroExterior: string,
		handleNumeroExterior: (e: React.ChangeEvent<HTMLInputElement>) => void,
		numeroInterior: string,
		handleNumeroInterior: (e: React.ChangeEvent<HTMLInputElement>) => void,
		entreCalle: string,
		handleEntreCalle: (e: React.ChangeEvent<HTMLInputElement>) => void,
		tipoDomicilio: SelectItemsTypes,
		handleTipoDomicilio: (e: SelectItemsTypes) => void,
		indicadoresAdicionales: string,
		handleIndicacionesAdicionales: (e: React.ChangeEvent<HTMLInputElement>) => void,
		dataTipoDomicilios: SelectItemsTypes[] | undefined,
		errorCodigoPostal: errorNumeroExt,
		erroCalle: errorNumeroExt,
		errorNumeroExt: errorNumeroExt,
		errorNumeroInt: errorNumeroExt,
		errorEntreCalle: errorNumeroExt,
		errorIndic: errorNumeroExt,
		errorGuardarDireccion: boolean,
		loadingColonias: boolean,
		handleGuardarDireccion: (handleBack?: ((value?: DireccionType) => void) | undefined) => void,
		loading: boolean,
		direccionPredeterminada: boolean,
		handleDireccionPredeterminada: () => void,
		disabledDireccionPredeterminada: boolean,
	},
	loadingButton: boolean,
	handleProgramarRecoleccion: () => void,
	dataSucursales: SelectItemsTypes[] | undefined,
	sucursal: SelectItemsTypes | null,
	handleSucursal: (suc: SelectItemsTypes) => void,
	dataCiudades: SelectItemsTypes[] | undefined,
	ciudad: SelectItemsTypes | null,
	handleCiudad: (value: SelectItemsTypes) => void,
	loadingSucursales: boolean,
	getDisabledButton: () => boolean | undefined,
	handleProgramarEnvio: () => void,
	fechaRecoleccion: string | null,
	handleFechaRecoleccion: (e: string) => void,
	horas: SelectItemsTypes[] | undefined,
	horaRecoleccion: SelectItemsTypes | null,
	handleHoraRecoleccion: (e: SelectItemsTypes) => void,
	detalleEnvio: DetalleEnvioType | null,
	today: string,
	handleCancelar: () => void,
	handleGoToModificarDomicilio: () => void,
	dataDirecciones: DireccionesTypes[] | undefined,
	currentDireccion: DireccionesTypes | null,
	handleCurrentDireccion: (direccion: DireccionesTypes) => void,
	xs?: boolean,
	handleReset: () => void,
	carrito: DataCarritoTypes | undefined,
	errorDate: ErrorTextFieldType,
	handleEntregarAhora: () => void
};

// falta funcion de guardar en local storage e inicializar los valores
const OpcionesEntregaModal : React.FC<Props> = ({ 
	open,
	handleClose,
	activePosition,
	handleAddDireccion,
	tipoStep,
	getFunctionButton,
	nuevaDireccion,
	loadingButton,
	handleProgramarRecoleccion,
	dataSucursales,
	sucursal,
	handleSucursal,
	dataCiudades,
	ciudad,
	handleCiudad,
	loadingSucursales,
	getDisabledButton,
	handleProgramarEnvio,
	isCheckout,
	fechaRecoleccion,
	carrito,
	handleFechaRecoleccion,
	horas,
	horaRecoleccion,
	handleHoraRecoleccion,
	detalleEnvio,
	today,
	handleCancelar,
	handleGoToModificarDomicilio,
	dataDirecciones,
	currentDireccion,
	handleCurrentDireccion,
	xs,
	handleReset,
	errorDate,
	handleEntregarAhora
} : Props) => {
	const getTitle = () => {
		switch (tipoStep) {
		case 1:
			return <>
				Detalles <br/> del envío
			</>;
		case 2:
			return <>
				Dirección de <br/> entrega
			</>;
		case 3:
			return <>
				Programar <br/> recolección
			</>;
		case 4:
			return <>
			Programar <br/> envío
			</>;
		
		default:
			return '';
		}
	};

	const getSecondStep = () => {
		switch (tipoStep) {
		case 2:
		case 6:
			return (
				<ShowContainer> 
					<NuevaDireccion 
						gralDisabled={loadingButton} 
						edit={tipoStep === 6}
						key={1}
						{...nuevaDireccion}
					/> 
				</ShowContainer>
			);

		case 3:
			return <Recoleccion 
				dataSucursales={dataSucursales}
				sucursal={sucursal}
				handleSucursal={handleSucursal}
				dataCiudades={dataCiudades}
				ciudad={ciudad}
				handleCiudad={handleCiudad}
				loadingSucursales={loadingSucursales}
			/>;

		case 4:
			return <ProgramarEnvio 
				fechaRecoleccion={fechaRecoleccion}
				handleFechaRecoleccion={handleFechaRecoleccion}
				carrito={carrito}
				errorDate={errorDate}
				isCheckout={isCheckout}
				horas={horas}
				horaRecoleccion={horaRecoleccion}
				handleHoraRecoleccion={handleHoraRecoleccion}
			/>;

		case 5:
			return <SeleccionarDireccion 
				dataDirecciones={dataDirecciones}
				handleNext={handleAddDireccion}
				currentDireccion={currentDireccion}
				handleCurrentDireccion={handleCurrentDireccion}
			/>;

		default:
			return <></>;
		}
	};
	
	return (
		<>
			<Modal 
				responsiveFullSize 
				height='90vh'
				action={tipoStep !== 1 ? (
					<Grid container spacing='10px' padding='20px 40px 25px 40px'>
						<Grid item flex>
							<Button disabled={loadingButton} onClick={handleCancelar} variant='outlined-round' fullWidth>
								Cancelar
							</Button>
						</Grid>
						<Grid item flex>
							<Button loading={loadingButton} onClick={getFunctionButton()} disabled={getDisabledButton()}  variant='contained-round' fullWidth>
								Continuar
							</Button>
						</Grid>
					</Grid>
				) : undefined}
				title={<Grid container textAlign='center' justifyContent='center' padding='0 0 10px 0'>
					<Title variant='h8'>{getTitle()}</Title>
				</Grid>}
				padding={xs ? '0 40px 0 40px' : '0 17px 0 17px'} width='100%' maxWidth="438px" close handleClose={() =>{
					handleClose();
					handleReset();
				}} 
				open={open}
			>
				<CustomCarrousel 
					activePosition={activePosition}
					items={[
						<>
							<OptionContainer>
								<Grid container alignItems='center' padding='0 0 5px 0'>
									<Typography color='titleGrey' padding='0 6px 0 0' variant='subtitle1bold'>{detalleEnvio?.nSucursal ? 'Sucursal de recolección' : 'Dirección de entrega'}</Typography>
									{
										detalleEnvio?.cTipoDomicilio && <Tag>
											{detalleEnvio.cTipoDomicilio}
										</Tag>
									}
									{
										isCheckout && (!detalleEnvio?.nSucursal && !detalleEnvio?.nDireccion) && <Grid item padding='0 0 0 3px'><IconCircleAlert color='red'></IconCircleAlert></Grid>
									}
									
								</Grid>
								<Grid container alignItems='flex-start'>
									<Grid item padding='0 5px 0 0'>
										{
											detalleEnvio?.nSucursal ? <StoreIcon /> : <LocationOnIcon></LocationOnIcon>
										}
										
									</Grid>
									<Grid container item flex alignItems='flex-start' direction='column'>
										<Typography color='titleGrey' variant='body2'>
											{detalleEnvio?.cSucursal ? (`Sucursal ${detalleEnvio?.cSucursal}`) : detalleEnvio?.nDireccion ? (`${detalleEnvio?.cCalle || ''} ${detalleEnvio?.cNumeroExterior ? `#${detalleEnvio?.cNumeroExterior}` : ''} ${detalleEnvio?.cColonia ? `Col. ${detalleEnvio?.cColonia}` : ''}` ): 'Ingresa la dirección a la que te gustaría que enviemos tu orden.'}
										</Typography>
										{
											detalleEnvio?.cMunicipio && <Typography variant='body2'>
												{`${detalleEnvio?.cMunicipio} ${detalleEnvio.cEstado}`}
											</Typography>
										}
									</Grid>
									{
										detalleEnvio?.nDireccion && <Grid width='52px' container justifyContent='center' item padding='5px 0 0 5px'>
											<IconButton onClick={handleGoToModificarDomicilio}><EditIcon  /></IconButton>
										</Grid>
									}
									<Button 
										onClick={detalleEnvio?.nSucursal ? handleProgramarRecoleccion : handleAddDireccion}
										margin='15px 0 10px 0' fullWidth variant={detalleEnvio?.nSucursal || detalleEnvio?.nDireccion ? 'outlined-round' : 'contained-round'}>
										{detalleEnvio?.nSucursal ? 'Editar sucursal de recolección' : detalleEnvio?.nDireccion ? 'Cambiar dirección de entrega' : 'Añadir dirección de entrega'}
									</Button>
									<Button onClick={detalleEnvio?.nSucursal ? handleAddDireccion : handleProgramarRecoleccion} fullWidth variant='underline'>
										{detalleEnvio?.nSucursal ?'Enviar a domicilio' : 'Recoger en sucursal'}
									</Button>
								</Grid>
							</OptionContainer> 

							<OptionContainer margin="16px 0 0 0">
								<Grid container alignItems='center' padding='0 0 5px 0'>
									<Typography color='titleGrey' padding='0 6px 0 0' variant='subtitle1bold'>{detalleEnvio?.nSucursal ? 'Fecha y hora de recolección' : 'Fecha y hora de entrega'}</Typography>
									{
										isCheckout && (detalleEnvio?.cValorHoraAgendada === 'Pendiente' || !detalleEnvio?.cValorHoraAgendada || fechaRecoleccion === null) && <Grid item padding='0 0 0 3px'><IconCircleAlert color='red'></IconCircleAlert></Grid>
									}
								</Grid>
								<Grid container alignItems='flex-start'>
									<Grid item padding='0 5px 0 0'>
										<ScheduleIcon></ScheduleIcon>
									</Grid>
									<Grid item flex textAlign='justify' container direction='column'>
										<Typography color='titleGrey' variant='body2'>
											{detalleEnvio?.dFechaAgendada  && detalleEnvio?.dFechaAgendada  !== today ? `Fecha: ${getUtcDate(detalleEnvio?.dFechaAgendada, 'LL')}` : 'Entregar ahora'}
										</Typography>
										<Typography variant='body2'>
											{detalleEnvio?.cValorHoraAgendada ? `Hora: ${detalleEnvio?.cValorHoraAgendada}` : detalleEnvio?.nSucursal ? 'Pasar a recoger al realizar el pago' : ''}
										</Typography>
									</Grid>
									<Button onClick={handleProgramarEnvio} margin='15px 0 10px 0' fullWidth variant='outlined-round'>
										Editar fecha y hora de entrega
									</Button>
									{
										!detalleEnvio?.cValorHoraAgendada?.includes('Entregar ahora') ? <Button loading={loadingButton} onClick={handleEntregarAhora} fullWidth variant='underline'>
											{`${detalleEnvio?.nSucursal ?  'Recoger' : 'Entregar'} ahora`}
										</Button> : <></>
									}
								</Grid>
							</OptionContainer>
						</>,

						getSecondStep()
					]}
				/>
			</Modal>
		</>
	);
};
export default (OpcionesEntregaModal);