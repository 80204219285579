import styled from 'styled-components';
import { TextFieldProps } from '../PaswordTextFiled/styled';
export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .titleContainer {
      font-weight: ${props => props.theme.text.weight.bold};
      font-size: ${props => props.theme.text.size.subtitle1};
      font-family: ${props =>props.theme.text.font.primary};
      padding: 6px 0 6px 0;
      border-bottom: ${props => `1px solid ${props.theme.colors.borderLightGrey}`};

    }
    margin-top: 5px;
    .hidden {
      visibility: hidden;
    }
    .hiddenContainer{
      height: 0;
      overflow: hidden;
    }
`;


export const InputContainer = styled.div<TextFieldProps>`
    display: flex;
    height: 52px;
    font-family: ${props => props.theme.text.font.primary};
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.white};
    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${props.theme.colors[props.error ? 'red' : props.check ? 'green' : 'grey']};
    `};
    border-radius: 4px;
    cursor: pointer !important;
    padding: 0 12px 0 12px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
          -webkit-appearance: none;

    }
    input[type=number]{
              -moz-appearance: textfield;
    }
`;
