import React from 'react';
import Grid from '../Grid';
import Title from '../Title';
import Typography from '../Typography';
import { ContainerIcon, FooterContainer, StyledLinkSpan, StyledTypo, UpperGrid} from './styled';
import LinkSpan from '../LinkSpan';
import { SERVICIOS,COMPANIA, SUCURSALES_MENU } from '~/constants/contents';
import { FacebookNuevoIcon, InstagramIcon,TiktokIcon } from '~/assets/icons';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.webp';
import moment from 'moment';
import { handleGoToAvisoCln, handleGoToAvisoMaza, handleGoToFb, handleGoToInsta, handleGoToTikTok } from '~/utils';
const Footer : React.FC = () => {
	const navigate = useNavigate();
	
	const routeChange = (ruta:string) =>{ 
		navigate(ruta);
	};

	return (
		<FooterContainer>
			<UpperGrid>
				<Grid container flex direction='column'>
					<Grid item container justifyContent='center' padding='0 0 9px 0'> 
						<img loading="lazy" draggable="false" width="201px" height="84.4px" alt='Imagen de panamá' src={Logo}></img>
					</Grid>
					<Grid container justifyContent='center'>
						<Grid padding='20px 0 20px 0' container alignItems='center' width='320px' >
							{
								SUCURSALES_MENU.map((municipio, i) => (
									<Grid justifyContent={municipio.justify} key={i} padding={municipio.padding} container item flex borderRight={municipio.borderRight} borderLeft={municipio.borderLeft}>
										<LinkSpan onClick={()=>routeChange(`/sucursales/${encodeURI(municipio.cMunicipio)}`)}>{municipio.cMunicipio}</LinkSpan>
									</Grid>
								))
							}

							{/* 
							<Grid padding='0 8px 0 0' container justifyContent='flex-end' item flex borderRight>
								<LinkSpan>Culiacán</LinkSpan>
							</Grid>
							<Grid padding='0 8px 0 8px' container flex justifyContent='center'>
								<LinkSpan>Los Mochis</LinkSpan>
							</Grid>
							<Grid padding='0 0 0 8px' container  item flex borderLeft>
								<LinkSpan>Mazatlán</LinkSpan>
							</Grid>
							*/}
						</Grid>
					</Grid>
					<Grid container justifyContent='center'>
						<Typography variant='subtitle2'>
							Email:
						</Typography>
						<Typography variant='regular' padding='0 0 48px 4px'>info@panama.com.mx</Typography>
					</Grid>

					<Grid container justifyContent='center' flex spacing='24px'>
						<ContainerIcon onClick={()=>handleGoToTikTok()}>
							<TiktokIcon />
						</ContainerIcon>
						<ContainerIcon onClick={()=>handleGoToFb()}>
							<FacebookNuevoIcon />
						</ContainerIcon>
						<ContainerIcon onClick={()=>handleGoToInsta()}>
							<InstagramIcon style={{cursor: 'pointer'}} width='40px' height='40px'/>
						</ContainerIcon>
					</Grid>
				</Grid>
				
				<Grid container flex direction='column'>
					<Title variant='h10' padding='0 0 16px 0'>Compañía</Title>
					<Grid container flex direction='column' spacing='20px' padding='0 0 70px 0'>
						{
							COMPANIA.map((item, i) => (
								<LinkSpan key={i} onClick={() => {routeChange(item.goto);}}>{item.nombre}</LinkSpan>
							))
						}
					</Grid>
					
					<Grid container flex direction='column' spacing='10px'>
						<Typography variant='subtitle1'>Horario de sucursales</Typography>
						<Grid container flex direction='column' spacing='8px'>
							<Typography variant='body1'>Restaurantes</Typography>
							<Typography variant='body1'>7:00 - 22:00</Typography>
						</Grid>
						<Grid container flex direction='column' spacing='8px'>
							<Typography variant='body1'>Pastelerías</Typography>
							<Typography variant='body1'>7:00 - 21:00</Typography>
						</Grid>
					</Grid>

				</Grid>
				<Grid container flex direction='column'>
					<Grid item container padding='0 0 16px 0' >
						<Title variant='h10'>Más servicios</Title>
					</Grid>
					<Grid padding='0 0 20px 0' container direction='column' spacing='20px'>
						{
							SERVICIOS.map((item, i) => (
								<LinkSpan key={i} onClick={() => {
									if(item.onclick){
										item.onclick();
									} else {
										routeChange(item.goto);
									}
								}}>{item.nombre}</LinkSpan>
							))
						}
					</Grid>
				</Grid>
				<Grid padding='0 0 20px 0' container textAlign='center' justifyContent='center' className='pd-l-60'>
					<StyledTypo variant='body1'>
						Copyright © {moment().format('YYYY')} – Panamá Restaurantes y Pastelerías. Todos los derechos reservados. Conoce nuestras Políticas de Compra, Términos y Condiciones.
					</StyledTypo>

					<StyledLinkSpan onClick={()=>routeChange('/privacidad')}>Centro de privacidad</StyledLinkSpan>

					<div className='hidden'>
						<Grid container flex direction='column' spacing='20px'>
							<Grid container flex direction='column' spacing='12px'>
								<Typography>Pasteleria Panama de Culiacan</Typography>
								<StyledLinkSpan onClick={()=>handleGoToAvisoCln()}>Aviso de privacidad.</StyledLinkSpan>
							</Grid>
							<Grid container flex direction='column' spacing='12px'>
								<Typography>Pasteleria Panama de Mazatlan</Typography>
								<StyledLinkSpan onClick={()=>handleGoToAvisoMaza()}>Aviso de Privacidad.</StyledLinkSpan>
							</Grid>
						</Grid>
					</div>

				</Grid>

			</UpperGrid>
		</FooterContainer>
	);
};
export default (Footer);