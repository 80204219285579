import moment from 'moment';
import { useState, useContext, useEffect } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { DataCiudadesTypes, DetalleEnvioType, DireccionesTypes, DireccionType, ErrorTextFieldType, SelectItemsTypes } from '~/interfaces';
import useDirecciones from './useDirecciones';
import useWizard from './useWizard';
import { AlertToastContext } from '~/context/alertToastContext';
import _ from 'lodash';
import useDialog from './useDialog';
import { getUtcDate } from '~/utils';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';

const useOpcionesEntrega = (nivelesTiendaId?: number | null, isCheckout?: boolean, nCupon?: number | null | undefined) => {
	const {
		open,
		handleShow,
		handleClose,
	} = useDialog();

	const apiUrl = import.meta.env.VITE_API_URL;
	const {showError} = useContext(AlertToastContext);

	const {
		detalleEnvio,
		headersApi,
		handleDetalleEnvio,
		token,
		dataCarrito,
		userInfo,
		setDataCarrito
	} = useContext(CookiesContext);

	const {
		warningOpcionesEntregaModal
	} = useContext(GeneralLayoutContext);

	const carrito = _.find(dataCarrito, o=>o.nValor === nivelesTiendaId);

	const {
		handleActivePosition,
		handleNext,
		activePosition,
		handleBack,
	} = useWizard();

	const {
		dataDirecciones,
		nuevaDireccion,
		setNDireccion,
		editarDireccion: {
			handleEditarDireccion,
			handleOpenEditarDireccion
		}
	} = useDirecciones(true);

	const [loadingContinue, setLoadingContinue] = useState(false);
	/* 
    1 main
    2 paso añadir domicilio
    3 paso recoger
    4 paso hora 
    5 paso seleccionar domicilio
	6 paso modifica domicilio
    */

	const [tipoStep, setTipoStep] = useState(1);

	const handleCloseOpcionesEntrega = () => {
		handleClose();
		setTipoStep(1);
	};

	const handleAddDireccion = () => {
		if(activePosition === 0){
			handleNext();
		}

		setTipoStep((dataDirecciones && dataDirecciones?.length > 0&& activePosition === 0 && !(userInfo?.invitado))? 5 : 2);

		handleOpenEditarDireccion({
			cCalle: undefined,
			cColonia: undefined,
			cEntreCalle: undefined,
			cEstado: undefined,
			cMunicipio: undefined,
			cNumeroExterior: undefined,
			cNumeroInterior: undefined,
			cReferencias: undefined,
			nColonia: undefined,
			nDireccion: userInfo?.invitado && dataDirecciones ? dataDirecciones[0]?.nDireccion : undefined,
			nEstado: undefined,
			nMunicipio: undefined,
			cCodigo_Postal: undefined,
			nTipoDomicilio: undefined,
			bDireccionPredeterminada: undefined,
		});
	};

	const handleProgramarRecoleccion = () => {
		setTipoStep(3);

		if(detalleEnvio){
			setCiudad(detalleEnvio.nMunicipio ? {
				nValor: detalleEnvio.nMunicipio,
				cValor: detalleEnvio.cMunicipio,
			} : (dataCiudades?.[0] || null));
			setSucursal(detalleEnvio?.nSucursal ? {
				nValor: detalleEnvio?.nSucursal,
				cValor: detalleEnvio?.cSucursal,
			} : null);
		}

		handleNext();
	};

	const handleProgramarEnvio = () => {
		setTipoStep(4);
		handleNext();
	};

	const getDisabledButton = () => {
		switch (tipoStep) {
		case 1:
			return false;
		case 2:
		case 6:
			return nuevaDireccion.errorGuardarDireccion;
		case 3:
			return sucursal === null || ciudad === null;
		case 4:
			return horaRecoleccion === null || fechaRecoleccion === null || errorDate.error;
		case 5:
			return currentDireccion === null;
		default:
			return ;
		}
	};

	const loadingCheckout = detalleEnvio?.nivelesTiendaId !== nivelesTiendaId;

	const getDireccionGlobal = (modal?: boolean, handleDone?: () => void) => {
		const body = {
			nNivelTienda: nivelesTiendaId
		};

		fetch(`${apiUrl}/APPWEB/ObtenerDireccionGlobal`, {
			method: 'POST',
			headers: headersApi,
			body: JSON.stringify(body)
		}).then((res) => res.json()).then((response) => {

			if(response.Result?.direccionGlobal) {
				const result: DetalleEnvioType = response.Result?.direccionGlobal;
				handleDetalleEnvio({...result, nivelesTiendaId: nivelesTiendaId || 0});
				if(modal){
					handleBack();
					setTipoStep(1);
				}
				if(handleDone) {
					handleDone();
				}
			} else {
				handleDetalleEnvio(null);
			}

			setLoadingContinue(false);
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	useEffect(() => {
		if(dataDirecciones?.[0] && userInfo?.invitado){
			setNDireccion(dataDirecciones[0].nDireccion || null);
		} else if(dataDirecciones && detalleEnvio?.nDireccion && currentDireccion === null) {
			const directionSelected = _.find(dataDirecciones, o=>o.nDireccion === detalleEnvio.nDireccion);

			setCurrentDireccion(directionSelected || null);
		}
	}, [dataDirecciones, detalleEnvio?.nDireccion]);
	

	// Obtienes los items del carrito
	const handleDataCarrito = () => {		
		const body = {
			nNivelTienda: isCheckout ? nivelesTiendaId :  0,
			nCupon: isCheckout && nCupon ? nCupon :  0,
			nTipoEntrega: isCheckout && detalleEnvio?.nTipoEntrega,
			dFechaEntrega: detalleEnvio?.dFechaAgendada || null,
			nColonia: isCheckout && detalleEnvio?.nColonia,
			nRegion: isCheckout && detalleEnvio?.nMunicipio,
			bUsarCupon: isCheckout && nCupon === null ? 0 : 1
		};

		fetch(`${apiUrl}/APPWEB/ObtenerCarrito`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: headersApi,
		}).then((res) => res.json()).then((response) => {
	
			if (response?.Result?.dataCarrito) {
				const helper = response.Result.dataCarrito;
				setDataCarrito(helper);
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	// detalle envio
	const handleModificaDireccionGlobal = (body: {nDireccion?: number | string, nSucursal?: number | string}) => {
		setLoadingContinue(true);

		fetch(`${apiUrl}/APPWEB/ModificaDireccionGlobal`, {
			method: 'POST',
			headers: headersApi,
			body: JSON.stringify({...body, nNivelTienda: nivelesTiendaId})
		}).then((res) => res.json()).then((response) => {

			if(response.Result.done){
				getDireccionGlobal(true, () => {
					handleCloseOpcionesEntrega();
				});
				handleDataCarrito();
			} else {
				showError('No pudo ser registrada esta información, inténtelo más tarde.');
				setLoadingContinue(false);
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	const [currentDireccion, setCurrentDireccion] = useState<DireccionesTypes | null>(null);

	const handleCurrentDireccion = (direccion: DireccionesTypes) => {
		setCurrentDireccion(direccion);
	};

	const handleDireccionDetalleEnvio = (value: DireccionType) => {
		const body = {
			nDireccion: value.nDireccion,
		};
		
		handleModificaDireccionGlobal(body);
	};

	// --- Opciones de recolección
	const [loadingSucursales, setLoadingSucursales] = useState(false);

	const [dataCiudades, setDataCiudades] = useState<SelectItemsTypes[] | undefined>([]);

	const [ciudad, setCiudad] = useState<null | SelectItemsTypes>(null);
	const handleCiudad = (value: SelectItemsTypes) => {
		setCiudad(value);
	};

	// Llena el combo de municipios
	useEffect(() => {
		if(dataCiudades?.length === 0){
			fetch(`${apiUrl}/APPWEB/ConsultaMunicipios?nEstado=${1}`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				if (response?.Result?.[0]) {
					const helper = response.Result[0];
					const helperData = helper.map((item: DataCiudadesTypes) => ({
						nValor: item.nMunicipio,
						cValor: item.cMunicipio
					}));
					setDataCiudades(helperData);
					if(helperData[0]){
						setCiudad(helperData[0]);
					}
				}
	
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, []);


	// Sucursales
	const [dataSucursales, setDataSucursales] = useState<SelectItemsTypes[] | undefined>([]);

	const [sucursal, setSucursal] = useState<null | SelectItemsTypes>(null);

	const handleSucursal = (suc: SelectItemsTypes) => {
		setSucursal(suc);
	};

	const handleRecolleccionDetalleEnvio = () => {
		const body = {
			nSucursal: sucursal?.nValor,
		};
		handleModificaDireccionGlobal(body);
	};

	// Llena el combo de Sucursales
	useEffect(() => {

		if(ciudad !== null){
			setDataSucursales([]);

			setLoadingSucursales(true);
			fetch(`${apiUrl}/APPWEB/ConsultaSucursales?nMunicipio=${ciudad.nValor}&nTipoSucursal=${0}&nNivelTienda=${nivelesTiendaId || 0}`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {

				if (response?.Result?.done && response?.Result?.dataSucursales) {
					const helper: SelectItemsTypes[]= response?.Result?.dataSucursales;
					setDataSucursales(helper);
					if(detalleEnvio?.cHoraAgendada && !(_.find(helper, o=>detalleEnvio?.nSucursal === o.nValor))){
						setSucursal(helper[0]);
					} 
					setLoadingSucursales(false);
				} else {
					setSucursal(null);
					setLoadingSucursales(false);
					setDataSucursales([]);
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [ciudad?.nValor, nivelesTiendaId, dataCarrito]);

	// --- Fecha y hora del modal de recolección
	const today = moment().format('YYYY-MM-DD');

	const [fechaRecoleccion, setFechaRecoleccion] = useState<string | null>(detalleEnvio?.dFechaAgendada ? getUtcDate(detalleEnvio?.dFechaAgendada) : today);
	const errorDateInitialState = {
		error: false,
		check: false,
		helperText: '',
	};

	
	const [errorDate, setErrorDate] = useState<ErrorTextFieldType>(errorDateInitialState);
	const handleFechaRecoleccion = (e: string) => {
		setFechaRecoleccion(e);
	};

	const [horaRecoleccion, setHoraRecoleccion] = useState<null | SelectItemsTypes>(null);

	const handleHoraRecoleccion = (e: SelectItemsTypes ) => {
		setHoraRecoleccion(e);
	};

	const [horas, setHoras] = useState<SelectItemsTypes[] | undefined>(undefined);

	useEffect(() => {
		if((detalleEnvio?.dFechaAgendada || open) && fechaRecoleccion){
			setHoras(undefined);
			const body = {
				dFechaAgenda: fechaRecoleccion,
				nNivelTienda: nivelesTiendaId
			};
			fetch(`${apiUrl}/APPWEB/ObtenerHorasDisponibles`, {
				method: 'POST',
				headers: headersApi,
				body: JSON.stringify(body)
			}).then((res) => res.json()).then((response) => {
				const resHora = response?.Result?.horasDisponibles;
				if (resHora) {
					const jHoras: SelectItemsTypes[] = resHora.jHoras || [];
					setHoras(jHoras);
					const dFechaAgendada = getUtcDate(resHora?.dFechaAgendada, 'YYYY-MM-DD');

					if(moment(fechaRecoleccion).isBefore(dFechaAgendada)){
						setErrorDate(
							{
								check: false,
								error: true,
								helperText: `Solo hay pedidos disponibles a partir del: ${getUtcDate(resHora?.dFechaAgendada, 'LL')}`
							}
						);
					} else {
						setErrorDate(errorDateInitialState);
					}

					if(detalleEnvio?.cValorHoraAgendada === 'Pendiente'){
						setHoraRecoleccion(null);
						if(isCheckout){
							handleShow();
							setTipoStep(4);
							handleActivePosition(1);
						}
					} else if(detalleEnvio?.cHoraAgendada && !(_.find(jHoras, o=>detalleEnvio?.cHoraAgendada === `${o.nValor}`))){
						setHoraRecoleccion(jHoras[0] || null);
					} else {
						setHoraRecoleccion({
							cValor: detalleEnvio?.cValorHoraAgendada || '',
							nValor: detalleEnvio?.cHoraAgendada || '',
						});
					}
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
	}, [fechaRecoleccion, open]);
	

	const handleProgramarEnvios = () => {
		setLoadingContinue(true);
		const body = {
			nNivelTienda: nivelesTiendaId,
			dFechaAgendada: fechaRecoleccion,
			hora: horaRecoleccion?.nValor
		};
		fetch(`${apiUrl}/APPWEB/ModificaFechaHoraGlobal`, {
			method: 'POST',
			headers: headersApi,
			body: JSON.stringify(body)
		}).then((res) => res.json()).then((response) => {

			if(response.Result.done){
				handleDataCarrito();
				getDireccionGlobal(true, () => {
					handleCloseOpcionesEntrega();
				});
			} else {
				showError('No pudo ser registrada esta información, inténtelo más tarde.');
				setLoadingContinue(false);
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	const handleEntregarAhora = () => {

		setLoadingContinue(true);
		const body = {
			dFechaAgendada: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			nNivelTienda: nivelesTiendaId,
			hora: 'Cagada'
		};
		fetch(`${apiUrl}/APPWEB/ModificaFechaHoraGlobal`, {
			method: 'POST',
			headers: headersApi,
			body: JSON.stringify(body)
		}).then((res) => res.json()).then((response) => {
			if(response.Result.done){
				getDireccionGlobal(undefined, () => {
					handleCloseOpcionesEntrega();
				});
			} else {
				showError('No pudo ser registrada esta información, inténtelo más tarde.');
				setLoadingContinue(false);
			}
		}).catch((error) => {
			console.error(error, 'error');
		});
	};

	const loadingButton = nuevaDireccion.loading || loadingContinue;

	const getFunctionButton = () => {
		switch (tipoStep) {
		case 2:
			return ()=>nuevaDireccion.handleGuardarDireccion((value) => {
				if(value){
					handleDireccionDetalleEnvio(value);
				}
			});
		case 3: 
			return () => handleRecolleccionDetalleEnvio();
		case 4: 
			return () => handleProgramarEnvios();
		case 5:
			return () => handleModificaDireccionGlobal({nDireccion: currentDireccion?.nDireccion});
		
		case 6:
			return ()=>handleEditarDireccion((value) => {
				if(value){
					handleDireccionDetalleEnvio(value);
				}
			});
		}
	};

	const handleCancelar = () => {
		handleBack();
		setTipoStep(1);
		
	};

	useEffect(() => {
		getDireccionGlobal();
	}, [token, nivelesTiendaId]);

	const handleGoToModificarDomicilio = () => {
		if(detalleEnvio) {

			const directionSelected = _.find(dataDirecciones, o=>o.nDireccion === detalleEnvio.nDireccion);
			if(directionSelected){
				handleOpenEditarDireccion({
					cCalle: directionSelected.cCalle,
					cColonia: directionSelected.cColonia,
					cEntreCalle: directionSelected.cEntreCalle,
					cEstado: directionSelected.cEstado,
					cMunicipio: directionSelected.cMunicipio,
					cNumeroExterior: directionSelected.cNumeroExterior,
					cNumeroInterior: directionSelected.cNumeroInterior,
					cReferencias: directionSelected.cReferencias,
					nColonia: directionSelected.nColonia,
					nDireccion: directionSelected.nDireccion,
					nEstado: directionSelected.nEstado,
					nMunicipio: directionSelected.nMunicipio,
					cCodigo_Postal: directionSelected.cCodigo_Postal,
					nTipoDomicilio: directionSelected.nTipoDomicilio,
					cTipoDomicilio: directionSelected.cTipoDomicilio,
					bDireccionPredeterminada: directionSelected.bDireccionPredeterminada,
				});
			}

			setTipoStep(6);
			handleNext();
		}
		
	};
	
	const handleReset = () => {
		handleActivePosition(0);
		setTipoStep(1);
	};

	/* 
    1 main
    2 paso añadir domicilio
    3 paso recoger
    4 paso hora 
    5 paso seleccionar domicilio
	6 paso modifica domicilio
    */

	const handleOpenModalByTipoPedido = (value?: number ) => {
		handleShow();
		
		if(value) {
			handleActivePosition(1);
			setTipoStep(value === 1 ? ((!(userInfo?.invitado))? 5 : 2) : 3);
		}
	};

	const handleContinue = (hadleDone: VoidFunction) => {
		const hoyFormat = moment((detalleEnvio?.dFechaAgendada ? getUtcDate(detalleEnvio?.dFechaAgendada) : fechaRecoleccion || today)).format('YYYY-MM-DD');
		
		if( ((carrito?.nRangoLibreFechasDisponibles === false &&  (((carrito?.dFechasDisponibles?.length || 0) > 0 && !(_.find(carrito?.dFechasDisponibles, o=>o.dFecha === hoyFormat))) || (moment(hoyFormat).isBefore(moment(carrito?.dFechasAPartirDe)) && hoyFormat !== carrito?.dFechasAPartirDe))) && carrito?.nValor === nivelesTiendaId) || detalleEnvio?.bHoraPendiente && detalleEnvio?.nNivelTienda === nivelesTiendaId){

			setHoraRecoleccion(null);
			setFechaRecoleccion(null);
			if(isCheckout){
				handleShow();
				setTipoStep(4);
				handleActivePosition(1);

			}
		} else {
			setFechaRecoleccion(detalleEnvio?.dFechaAgendada ? getUtcDate(detalleEnvio?.dFechaAgendada) : today);
			if(isCheckout){
				if(((!detalleEnvio?.nDireccion) && (!detalleEnvio?.nSucursal)) && detalleEnvio?.nNivelTienda === nivelesTiendaId){
					handleShow();
				} else {
					handleClose();
					if(hadleDone){
						hadleDone();
					}
				}
			}
		}
	};

	useEffect(() => {
		if(warningOpcionesEntregaModal){
			handleClose();
		}
	}, [warningOpcionesEntregaModal]);

	const handleOpen = () => {
		handleShow();
		setFechaRecoleccion(detalleEnvio?.dFechaAgendada ? getUtcDate(detalleEnvio?.dFechaAgendada) : today);
	};

	return {
		handleOpenModalByTipoPedido,
		activePosition,
		handleAddDireccion,
		tipoStep,
		getFunctionButton,
		nuevaDireccion,
		loadingButton,
		handleProgramarRecoleccion,
		dataSucursales,
		sucursal,
		handleSucursal,
		loadingCheckout,
		dataCiudades,
		ciudad,
		handleCiudad,
		loadingContinue,
		loadingSucursales,
		getDisabledButton,
		handleProgramarEnvio,
		fechaRecoleccion,
		handleFechaRecoleccion,
		horas,
		horaRecoleccion,
		handleHoraRecoleccion,
		detalleEnvio,
		today,
		handleCancelar,
		handleGoToModificarDomicilio,
		dataDirecciones,
		currentDireccion,
		handleCurrentDireccion,
		handleDataCarrito,
		carrito,
		handleReset,
		open,
		handleContinue,
		handleOpen: handleOpen,
		handleClose: handleCloseOpcionesEntrega,
		handleEntregarAhora,
		errorDate,
	};
};

export default useOpcionesEntrega;

// setDetalledEnvioModal