import styled from 'styled-components';


export const WaveCardContainer = styled.div`
    background: ${props => props.theme.colors.primary};
    padding: 20px;
`;

export const Waves = styled.div`
    background-image: url("/semicircle.png");
    background-repeat: repeat-x;
    transform: rotateX(3.142rad);
    height: 17px;
`;

export const WavesTop = styled.div`
    background-image: url("/semicircle.png");
    background-repeat: repeat-x;
    height: 17px;
`;