import React from 'react';
import { ControlledSpinnerContainer } from './styled';
import colors from '~/styles/colors';
import { useTimer } from '~/hooks';
type Props = {
    handleFinish: VoidFunction
};
const ControlledSpinner : React.FC<Props> = ({ handleFinish } : Props) => {

	const Progress = ({ strokeWidth, percentage }: {strokeWidth: number, percentage: number}) => {
		const radius = (50 - strokeWidth / 2);
		const pathDescription = `
			M 50,50 m 0,-${radius}
        	a ${radius},${radius} 0 1 1 0,${2 * radius}
        	a ${radius},${radius} 0 1 1 0,-${2 * radius}
        `;
    
		const diameter = Math.PI * 2 * radius;
		const progressStyle: React.CSSProperties = {
			stroke: colors.green,
			strokeLinecap: 'round',
			strokeDasharray: `${diameter}px ${diameter}px`,
			strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
			transition: 'all 0.3s ease-in-out'
		};
    
		return (
			<svg
				className={'CircularProgressbar'}
				viewBox="0 0 100 100"
				width={100}
				height={100}
				style={{transform: 'scale(0.7)'}}
			>
				<path
					className="CircularProgressbar-trail"
					d={pathDescription}
					strokeWidth={strokeWidth}
					fillOpacity={0}
					style={{
						stroke: colors.white,
					}}
				/>
    
				<path
					className="CircularProgressbar-path"
					d={pathDescription}
					strokeWidth={strokeWidth}
					fillOpacity={0}
					style={progressStyle}
				/>
    
				{/* <text
					className="CircularProgressbar-text"
					x={50}
					y={50}
					style={{
						fill: colors.green,
						fontSize: '24px',
						dominantBaseline: 'central',
						textAnchor: 'middle',
					}}
				>
					{`${percentage}%`}
				</text> */}
			</svg>
		);
	};
	const {
		progress
	} = useTimer(5, handleFinish);
	return (
		<ControlledSpinnerContainer><Progress strokeWidth={17} percentage={100 - ((progress) * 20)} /></ControlledSpinnerContainer>
	);
};
export default (ControlledSpinner);