import styled, { css } from 'styled-components';

export interface Props {
    bold?: boolean;
    padding?: string;
    color?: string;
    family?: string;
    margin?: string;
    lineHeight?: string;
    variant?: string
  }

const Paragraph = styled.p<Props>`
padding: ${props =>props.padding || '0'};

  margin: ${props =>(props.margin || '0px')};
  font-family: ${props =>props.theme.text.font.primary};
  color: ${props => props.theme.colors[props.color ? props.color: 'black']};
  white-space: pre-line;
  line-height: ${props =>props.lineHeight ? props.lineHeight : '1.2'};

  ${props => {
		switch (props.variant) {
		case 'body1':
			return css`
          font-size: ${props.theme.text.size.subtitle1};
          font-weight: ${props.theme.text.weight.medium};
        `;
		case 'body2':
			return css`
          font-size: ${props.theme.text.size.subtitle2};
          font-weight: ${props.theme.text.weight.medium};
        `;
		case 'caption':
			return css`
          font-size: ${props.theme.text.size.caption};
          font-weight: ${props.theme.text.weight.regular};

        `;
		case 'overline':
			return css`
          font-size: ${props.theme.text.size.overline};
          font-weight: ${props.theme.text.weight.regular};

        `;
		default:
			return css`
          font-weight: ${props.theme.text.weight.regular};
          font-size: ${props.theme.text.size.subtitle2};
      `;
		}
	}}
`;
export default Paragraph;