import React from 'react';
import { ExtraContainer } from '../detalleProducto/styled';
import { CardMedia, Grid, IconButton, QuantityContainer, Typography } from '~/components';
import { AddFillIcon } from '~/assets/icons';
import { getCdnUrl, pricePrettify } from '~/utils';
import { DataComplementosProps, ProductosExtrasProps } from '~/interfaces';
type Props = {
	handleAddExtras: (e: number, remove?: boolean | undefined) => void
	i: number,
	apiUrl: string,
	item: DataComplementosProps | ProductosExtrasProps,
	cImagenUrl?: string,
	cDescripcion: string,
	nPrecio: number,
	cDetalle?: string,
	nCantidad?: number,
};
const ExtrasContainer : React.FC<Props> = ({ cImagenUrl,
	cDescripcion,
	nPrecio,
	cDetalle,
	item,
	nCantidad, handleAddExtras, i } : Props) => {
	return (
		<ExtraContainer key={i}>
			<Grid width='50px' container alignItems='center' padding='10px 0 10px 10px'>
				{
					cImagenUrl && <CardMedia alt={'Extra de ' + cDescripcion} height='40px' maxwidth='40px' src={getCdnUrl(cImagenUrl)}></CardMedia>
				}
			</Grid>
			<Grid spacing='4px' padding='10px' flex container justifyContent='center' direction="column">
				<Typography variant='subtitle2'>{cDescripcion}</Typography>
				<Grid container>
					{
						!!(item?.bPrecioEspecial && item.nPrecio_Base_Original && item.nProductoSIP) && <Typography padding='0 5px 0 0' variant='lineCaption'>
							{pricePrettify(item.nPrecio_Base_Original)}
						</Typography>
					}
					
					<Typography  variant='caption'>{pricePrettify(nPrecio)}</Typography>

				</Grid>
				{
					cDetalle && <Typography color='ratGrey' variant='caption'>{cDetalle}</Typography>
				}
			</Grid>
			<Grid item flex justifyContent='flex-end' container>
				{
					nCantidad ? <QuantityContainer
						nCantidad={nCantidad}
						handleRemove={() => handleAddExtras(i, true)}
						handleAdd={() => handleAddExtras(i)}
					></QuantityContainer> : <IconButton onClick={() => handleAddExtras(i)}>
						<AddFillIcon />
					</IconButton>
				}
			
			</Grid>
		</ExtraContainer>
	);
};
export default (ExtrasContainer);