import styled, { css } from 'styled-components';


export interface FooterProps {
  singleMenu?: boolean;
}

export const Footer = styled.div<FooterProps>`
    background-color: ${props => props.theme.colors.white};
    padding: 10px 20px 10px 20px;
    flex-direction: column;
    display: flex;
    width: 400px;
    @media (max-width: 824px) {
        width: 100vw;
    }
    box-sizing: border-box;
    ${props => props.singleMenu ? css`
    border-radius: 0 0 8px 8px;
    border-top: 1px solid ${props.theme.colors.borderLightGrey};

    ` : css`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid ${props.theme.colors.borderLightGrey};
    
    `}
`;