import styled, { css, keyframes } from 'styled-components';

export interface CheckboxContainerProps {
  checked?: boolean;
}

const check = keyframes`
from {
    transform: scale(0.9);
    display: none;
    opacity: 0;
}
to {
    opacity: 1;
    display: block;
    transform: scale(1.0);
}
`;
const unCheck = keyframes`
from {
    opacity: 1;    
    transform: scale(1.0);
    display: block;
}
to {
    transform: scale(0.9);
    opacity: 0;    
    display: none;
}
`;

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  .icon{
        position: relative;
        width: 24px;
        height: 24px;
    }
    .check {
        position: absolute;
    }
        
    .uncheck {
        position: absolute;
    }

    ${props => props.checked === true ? css`
        .check {
            animation: ${check} 0.2s forwards;
        }

        .uncheck {
            animation: ${unCheck} 0.2s forwards;
        }
    
    ` : props.checked === false ? css`
        .check {
            animation: ${unCheck} 0.2s forwards;
        }

        .uncheck {
            animation: ${check} 0.2s forwards;
        }
    ` : css`
        .check {
            opacity: 0;
        }

        .uncheck {
            opacity: 1;
        }
    `}
`;