import React from 'react';
import { IconPlus } from '~/assets/icons';
import CheckBox from '~/components/CheckBox';
import { DirectionCardContainer } from '~/components/DireccionCard/styled';
import Grid from '~/components/Grid';
import IconButton from '~/components/IconButton';
import Tag from '~/components/Tag';
import Typography from '~/components/Typography';
import { DireccionesTypes, VoidFunctionType } from '~/interfaces';
type Props = {
    handleNext: VoidFunctionType,
    dataDirecciones:  DireccionesTypes[] | undefined
    currentDireccion: DireccionesTypes | null,
    handleCurrentDireccion: (value: DireccionesTypes)=>void
};
const SeleccionarDireccion : React.FC<Props> = ({ handleNext, dataDirecciones, currentDireccion,handleCurrentDireccion } : Props) => {
	return (
		<>
			<DirectionCardContainer onClick={handleNext}>
				<Grid padding='0 10px 0 0'>
					<Typography variant='body1'>Añadir dirección de entrega</Typography>
				</Grid>
				<Grid item >
					<IconButton  onClick={handleNext}><IconPlus /></IconButton>
				</Grid>
			</DirectionCardContainer>

			{
				dataDirecciones?.map((direccion, i) => (
					<DirectionCardContainer onClick={()=>handleCurrentDireccion(direccion)} key={i}>
						<Grid item flex container direction='column'>
							<Typography padding='0 0 10px 0' variant='body1'>{`${direccion.cCalle} ${direccion.cNumeroExterior}`}</Typography>
							<Grid item container>
								<div>
									<Tag>{direccion.cTipoDomicilio}</Tag>
								</div>

							</Grid>
						</Grid>
						<Grid item width='32px'>
							<CheckBox radio onClick={()=>handleCurrentDireccion(direccion)} checked={currentDireccion ?currentDireccion.nDireccion === direccion.nDireccion : false} />
						</Grid>
					</DirectionCardContainer>
				))
			}
		</>
	);
};
export default (SeleccionarDireccion);