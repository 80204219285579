import { useContext, useEffect, useState } from 'react';
import useWizard from './useWizard';
import { ICupon } from '~/interfaces';
import { CookiesContext } from '~/context/cookiesContext';
import errors from '~/constants/errors';
import { AlertToastContext } from '~/context/alertToastContext';
import { handleValidDinamicaFolio } from '~/utils/validations';
import { useNavigate } from 'react-router-dom';

const useCupones = (isMain?: boolean) => {
	const apiUrl = import.meta.env.VITE_API_URL;
	
	const {
		headersApi,
		handleDoneInitialLoading,
		userInfo
	} = useContext(CookiesContext);
	
	const {
		showError
	} = useContext(AlertToastContext);
	
	const [openCuponesModal, setOpenCuponesModal] = useState(false);
	
	const handleCloseCuponesModal = () => {
		setOpenCuponesModal(false);
	};
	
	const cuponErrorInitialState = {
		error: false,
		helperText: '',
	};
	const [cuponError, setCuponError] = useState(cuponErrorInitialState);

	const [currentCupon, setCurrentCupon] = useState<ICupon | null | undefined>(undefined);

	const handleCurrentCupon = (value: ICupon | null) => {
		setCurrentCupon(value && value.nCupon === currentCupon?.nCupon ? null : value);
	};

	const [addCupon, setAddCupon] = useState(false);

	const handleAddCupon = () => {
		setAddCupon(true);
	};

	const handleCancelAddCupon = () => {
		setAddCupon(false);
	};

	const [cupon, setCupon] = useState('');

	const handleCupon = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCupon(e.target.value);
		setCuponError(cuponErrorInitialState);
	};

	const {
		activePosition: activePositionCupon,
		handleNext: handleNextCupon,
		handleBack: handleBackCupon,
		handleActivePosition: handleActivePositionCupon,
	} = useWizard();

	const handleOpenCuponesModal = () => {
		setOpenCuponesModal(true);
		setCuponError(cuponErrorInitialState);
		handleActivePositionCupon(0);
	};

	const [loadingCupon, setLoadingCupon] = useState(false);
	const [loadingConsultaCupones, setLoadingConsultaCupones] = useState(false);
	const [dataCupones, setDataCupones] = useState<ICupon[] | undefined>(undefined);

	const handleConsultaCupones = () => {
		setLoadingConsultaCupones(true);
		fetch(`${apiUrl}/APPWEB/ObtenerCuponesUsuario`, {
			method: 'POST',
			headers: headersApi
		}).then((res) => res.json()).then((response) => {
			if (response?.Result?.dataCupones) {
				setLoadingConsultaCupones(false);
				setDataCupones(response.Result.dataCupones);
				handleDoneInitialLoading();
			} 

		}).catch((error) => {
			console.error(error, 'error');
			setDataCupones([]);
			setLoadingConsultaCupones(false);

		});
	};
	useEffect(() => {
		handleConsultaCupones();
	}, []);

	const navigate = useNavigate();
	const handleCanjearCupon = () => {
		// Validar que el usuario no haya escrito el folio de una dinamica en el lugar de un Cupon 💀💀
		const { check } = handleValidDinamicaFolio(cupon);

		if (isMain && check) {
			// En caso de ser asi te redirecciona a la ruta de dinámicas
			showError(errors.redirectDinamicasMessage);
			navigate(`/dinamicas/?${new URLSearchParams({ folio: cupon })}`);
			return;
		} 
		
		const body = {
			cNombre: cupon,
		};
		setLoadingCupon(true);
		fetch(`${apiUrl}/APPWEB/RegistrarCupon`, {
			method: 'POST',
			headers: headersApi,
			body: JSON.stringify(body),
		}).then((res) => res.json()).then((res) => {
			
			if(res?.Result?.dataCupon?.done){
				handleBackCupon();
				setLoadingCupon(false);
				handleConsultaCupones();
				if(isMain){
					handleCloseCuponesModal();
				}

			} else if (res?.Result?.dataCupon?.error){
				setCuponError(
					{
						error: true,
						helperText: res?.Result?.dataCupon?.error,
					}
				);
			}
			setLoadingCupon(false);
		}).catch((error) => {
			setLoadingCupon(false);
			console.error(error, 'error');
			setCuponError(
				{
					error: true,
					helperText: errors.errorForm,
				}
			);
		});
	};

	return {
		openCuponesModal,
		handleOpenCuponesModal,
		handleCloseCuponesModal,
		handleCurrentCupon,
		currentCupon,
		cupon,
		handleCupon,
		addCupon,
		handleAddCupon,
		handleCancelAddCupon,
		cuponError,
		setCurrentCupon,
		activePositionCupon,
		handleCanjearCupon,
		handleNextCupon,
		handleConsultaCupones,
		handleBackCupon,
		loadingCupon,
		loadingConsultaCupones,
		dataCupones,
		userInfo
	};
};

export default useCupones;