// text.ts
const text = {
	font: {
		primary: '"Poppins", sans-serif'
	},
	size: {
		h1: '96px',
		h2: '60px',
		h3: '48px',
		h4: '34px',
		h5: '24px',
		h6: '20px',
		h7: '18px',
		h8: '32px',
		h9: '34px',
		h10: '22px',

		subtitle3: '18px',
		subtitle1: '16px',
		subtitle2: '14px',

		caption: '12px',
		overline: '10px',
	},
	weight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	}
};
export default text;