import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px 5px 0 5px;
    box-sizing: border-box;
    .header {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 2;
    }
`;