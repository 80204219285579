import React  from 'react';
import './styles/App.css';
import RouterMain from './router';
import './locale/es-mx';
import { StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from './utils';

const App : React.FC = () => {
	return (
		<StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
			<RouterMain />
		</StyleSheetManager>
	);
};

export default App;
