import moment from 'moment';
import 'moment/locale/es';  // without this line it didn't work
moment.locale('es');
import React from 'react';
import { HeartIcon } from '~/assets/icons';
import { HistorialOrdenesCardTypes } from '~/interfaces';
import { pricePrettify } from '~/utils';
import Button from '../Button';
import { DirectionCardContainer } from '../DireccionCard/styled';
import Grid from '../Grid';
import Typography from '../Typography';
type Props = {
    item: HistorialOrdenesCardTypes,
    onClick?: VoidFunction,
};
const HistorialOrdenesCard : React.FC<Props> = ({ onClick, item } : Props) => {

	return (
		<DirectionCardContainer onClick={onClick}>
			<Grid item  padding='0 10px 0 0'>
				<div className='iconContainer'>
					<HeartIcon height='30' width='30' color='orange'></HeartIcon>
				</div>
			</Grid>
			<Grid justifyContent='center' item flex container direction='column' padding='0 10px 0 0'>
				<Typography variant='subtitle2'>
					{item.cSucursal}
				</Typography>
				<Typography color='darkGrey61' variant='subtitle2'>{`${item.nTotalCantidad} artículo${item.nTotalCantidad > 1 ? 's' : ''} - ${pricePrettify(item.nTotal)}`}</Typography>
				<Typography variant='caption'>
					{moment(item.dFechaEntrega).format('LL')}
				</Typography>
			</Grid>
			<Grid container justifyContent='center'>
				<Button onClick={onClick} variant='contained'>Ver detalle</Button>
			</Grid>
		</DirectionCardContainer>
	);
};
export default (HistorialOrdenesCard);