import styled from 'styled-components';

export interface ChevronUpStyledProps {
    isShowing?: boolean;
  }
export const ChevronUpStyled = styled.div <ChevronUpStyledProps>`
    transition: all 0.3s ease ;
    transform: ${props => props.isShowing ? 'rotate(180deg)' : ''};
  `;
  
export const ShowContainer = styled.div <ChevronUpStyledProps>`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    max-height: ${props => props.isShowing ? '100%' : '0'};
    opacity: ${props => props.isShowing ? '1' : '0'};
    transition: all 0.3s ease-in-out;
  `;