import React from 'react';
import Typography from '../Typography';
import { ProfileCardsContainer } from './styled';
type Props = {
  children: JSX.Element | JSX.Element[],
  title?: string,
  onClick: VoidFunction,
};
const ProfileCards : React.FC<Props> = ({ title, children, onClick } : Props) => {
	return (
		<ProfileCardsContainer onClick={onClick} className='noselect'>
			{children}
			<Typography padding='2px 0 0 0' variant='body3'>{title}</Typography>
		</ProfileCardsContainer>
	);
};
export default (ProfileCards);