import React from 'react';
import { WarningContainer } from './styled';
import Grid from '../Grid';
import { IconCircleAlert } from '~/assets/icons';
type Props = {
  title: string,
};
const Warning : React.FC<Props> = ({ title } : Props) => {
	return (
		<WarningContainer>
			<Grid item container width='35px' alignItems='center'><IconCircleAlert color='ocher'/></Grid>
			<Grid item flex container >
				{title}
			</Grid>
		</WarningContainer>
	);
};
export default (Warning);