import styled from 'styled-components';
import Grid from '../Grid';

export interface NivelesAppBarContainerProps {
  padding?: string;
}

export const NivelesAppBarContainer = styled.div<NivelesAppBarContainerProps>`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 5px 8px 5px;
    border-bottom: 1px solid ${props => props.theme.colors.borderLightGrey};

`;

export interface NivelTabProps {
  active?: boolean;
}

export const NivelTab = styled.div<NivelTabProps>`
    font-size: ${props => props.theme.text.size.subtitle1};
    font-weight: ${props => props.theme.text.weight[props.active ? 'bold' : 'medium']};
    font-family: ${props =>props.theme.text.font.primary};
    white-space:nowrap;
    z-index: 2;
    
    transition: border-bottom 0.3s ease-in-out;
    &:hover {
        cursor: pointer;
    }
`;

export const ActiveDivider = styled.div<NivelTabProps>`
  background-color: ${props => props.theme.colors[props.active ? 'primary' : 'transparent']};
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 4px;
  position: relative;
    z-index: 2;
  border-radius: 4px;

`;

export interface ScrollContainerProps {
  padding?: string;
  marginRight?: string;
}
export const ScrollContainer = styled(Grid)<ScrollContainerProps>`
  position: relative;
  height: 50px;
  .container > *:not(:last-child){
    margin-right: ${props => props.marginRight || '21px'};
  }
  .container {
    overflow: auto;
    padding: ${props => props.padding || '0 10px 0 0'};
    position: absolute;
    left: 0;
    align-items: center;
    bottom: 0;
    top: 0;
    right: 0;
    display: inline-flexbox;
  }
`;