import React from 'react';
import { Button, Grid, Modal, Typography } from '..';
type Props = {
    handleClose: VoidFunction,
	open: boolean,
    handleAcept: VoidFunction,
    warningLabel: string | JSX.Element | JSX.Element[],
    width?: string,
    loading?: boolean,
	children?: JSX.Element | JSX.Element[],
	height?: string
	cancelLabel?: string,
	aceptLabel?: string,
};
const WarningModal : React.FC<Props> = ({ 
	handleClose,
	open,
	warningLabel,
	width,
	loading,
	children,
	handleAcept,
	cancelLabel,
	aceptLabel,
	height
} : Props) => {
	return (
		<Modal
			width={'100%'}
			maxWidth={width || '341px'}
			open={open}
			handleClose={handleClose}
			height={height}
			action={<Grid container spacing='5px' padding='35px 15px 15px 15px'>
				<Grid item flex>
					<Button fullWidth variant='outlined-round' onClick={handleClose}>
						{cancelLabel||'No, cancelar'}
					</Button>
				</Grid>
				<Grid item flex>
					<Button loading={loading} fullWidth variant='contained-round' onClick={handleAcept}>

						{aceptLabel||'Sí, Seguro'}
				
					</Button>
				</Grid>     
			</Grid>}
			close
		>
			<Grid container justifyContent='center' textAlign='center'>
				<Typography variant='subtitle1bold'>{warningLabel}</Typography>
			</Grid>
			<>
				{children}
			</>
			

		</Modal>
	);
};
export default (WarningModal);