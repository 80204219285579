import styled, { css } from 'styled-components';

export interface CarritoCardContainerProps {
  padding?: string;
  checkout?: boolean
}

export const CarritoCardContainer = styled.div<CarritoCardContainerProps>`
    background: ${props => props.theme.colors.white};
    display: flex;
    box-sizing: border-box;
    padding-right: 10px;
    ${props => props.checkout ? null : css`
    border: 1px solid ${props.theme.colors.borderLightGrey};
    cursor: pointer;
    border-radius: 8px;
    
    `}
    img{
      border-radius: 8px;

    }

`;
