import styled, { keyframes } from 'styled-components';

const show = keyframes`
  0%{
    transform:  scale(1)  ;
  }
  15% {
    transform:  scale(.75)  ;
  }
  30% {
    transform:  scale(1) ;
  }
  45% {
    transform:  scale(.85)  ;
  }
  60% {
    transform:  scale(1)  ;
  }
  75% {
    transform:  scale(.95)  ;
  }
  100% {
    transform:  scale(1)  ;
  }
`;

const showOpacity = keyframes`
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
`;
export interface DoneContainerProps {
  padding?: string;
  width?: string;
}
export const DoneContainer = styled.div<DoneContainerProps>`
  padding: ${props => props.padding ? props.padding : '10% 0 0 0'};
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  animation: ${show} 0.6s, ${showOpacity} 0.6s;
  z-index: 2;

`;

