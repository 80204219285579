import styled from 'styled-components';
import { Grid } from '~/components';

export interface ChevronUpStyledProps {
    isShowing?: boolean;
}

export interface GridStyledProps {
    isShowing?: boolean;
}

export const ChevronUpStyled = styled.div <ChevronUpStyledProps>`
    stroke:black;
    transition: all 0.3s ease ;
    transform: ${props => props.isShowing ? 'rotate(180deg)' : ''};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GridStyled = styled(Grid) <GridStyledProps>`
    overflow: hidden;
    height: ${props => props.isShowing ? 'auto' : '0'};
    opacity: ${props => props.isShowing ? '1' : '0'};
    transition: all 0.3s ease-in-out;
`;

export const ScrollContainer = styled.div`

    @media (max-width: 650px) {
        height: calc(100% - (72px + 73px));
    }
    height: calc(100vh - (121px + 73px));
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 16px 30px 16px;

    .pointer{
        cursor: pointer;
    }

`;