import { useContext, useEffect, useState } from 'react';
import useDialog from './useDialog';
import { CookiesContext } from '~/context/cookiesContext';

const useInfoModal = (bEnCheckOut = false,
	nNivelTienda = 0) => {
	const {
		headersApi,
	} = useContext(CookiesContext);
	
	const {
		open,
		handleClose,
		handleShow
	} = useDialog();
	const initialData = {
		bMostrar: false,
		bUrlClickPestañaNueva: false,
		cImagenUrl: '',
		cUrlClick: ''
	};
	const [infoModalData, setinfoModalData] = useState(initialData);

	const apiUrl = import.meta.env.VITE_API_URL;

	const handleClick = () => {
		if(!infoModalData.cUrlClick) {
			return;
		}

		if(infoModalData.bUrlClickPestañaNueva) {
			window.open(infoModalData.cUrlClick);
		} else {
			window.location.href = infoModalData.cUrlClick;
		}
	};

	useEffect(() => {
		if(headersApi.Authorization){
			fetch(`${apiUrl}/APPWEB/ObtenerModalPublicidad?bEnCheckOut=${bEnCheckOut}&nNivelTienda=${nNivelTienda}`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				const data = response?.Result?.dataModalPublicidad?.[0];
				if(data?.bMostrar){
					setinfoModalData(data);
					handleShow();
				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
			
	}, []);

	return {
		open,
		handleClose,
		handleShow,
		handleClick,
		infoModalData
	};
};

export default useInfoModal;