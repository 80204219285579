import styled from 'styled-components';
import colors from '~/styles/colors';

export interface TextFieldProps {
  icon?: boolean;
  error?: boolean;
  check?: boolean;
  disabled?: boolean;
}

export interface TextFieldContainerInputProps {
  icon?: boolean;
  padding?: string;
  width?: string;
  fullWidth?: boolean;
}




export const IconContainer = styled.div<TextFieldProps>`
    border-radius: ${props => props.icon ? '4px 0px 0px 4px' : '4px'};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px 10px 12px;
    color: ${props =>props.theme.colors.inputGrey};

`;

export const TextFieldContainerInput = styled.div<TextFieldProps>`
    display: flex;
    font-family: ${props => props.theme.text.font.primary};

    min-height: 52px;
    height: 100%;

    background-color: ${props => props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
    ${props => `
      border: ${props.error || props.check ? '2px' : '1px'} solid ${colors[props.error ? 'red' : props.check ? 'green' : 'grey']};
    `};
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 10px;
    input {
      padding: 10px 12px 10px 12px;
      border: none !important;
      flex: auto;
      width: 100%;
      background-color: ${props => props.theme.colors[props.disabled ? 'inputGrey' : 'white']};
      font-family: ${props =>props.theme.text.font.primary};
      min-height: 30px;
      border-radius: ${props => props.icon ? '4px 0px 0px 4px' : '4px'};

      &:focus {
        outline: none;
      }
    }
`;

export const TextFieldContainer = styled.div<TextFieldContainerInputProps>`
    display: flex;
    box-sizing: border-box;
    flex: ${props => props.width && props.fullWidth ? null : 'auto'};
    flex-direction: column;
    width: ${props => props.fullWidth ? '100%' : props.width};
    max-width: ${props => props.fullWidth ? null : '358px'};
    border-radius: 4px;
    padding: ${props => props.padding};
`;
export const TextFieldLabel = styled.span`
    color: ${props =>props.theme.colors.textFieldLabel};
    padding-bottom: 4px;
    font-size: ${props =>props.theme.text.size.subtitle1};
    font-weight: ${props =>props.theme.text.weight.semiBold};
    font-family: ${props =>props.theme.text.font.primary};
`;