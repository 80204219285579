import React from 'react';
import CheckBox from '../CheckBox';
import Grid from '../Grid';
import Typography from '../Typography';
type Props = {
    disabled?: boolean,
    onClick?: () => void,
    checked?: boolean,
    radio?: boolean,
    variant?: string,
    color?: string,
    padding?: string,
    children: JSX.Element | JSX.Element[] | string,
};
const ControlLabel : React.FC<Props> = ({ disabled, onClick, variant, checked, radio, color, children, padding} : Props) => {
	return (
		<Grid padding={padding} container alignItems='center'>
			<CheckBox disabled={disabled} onClick={disabled ?undefined : onClick} checked={checked} radio={radio} />
			<Grid item flex padding='0 0 0 2px'>
				<Typography className='noselect' onClick={disabled ?undefined : onClick} pointer={!disabled} color={disabled ? 'disabledFontGrey' : color} variant={variant || 'body2'}>
					{children}
				</Typography>
			</Grid>

		</Grid>
	);
};
export default (ControlLabel);