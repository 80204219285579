import styled, { css } from 'styled-components';


export const QuantityContainer = styled.div`
    ${props => css`
        background-color: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.borderLightGrey};

        font-weight: ${props.theme.text.weight.bold};
        font-family: ${props.theme.text.font.primary};
    `}
    text-align: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;
    display: flex;
    min-height: 37px;
    max-height: 56px;
    box-sizing: border-box;
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px 0 5px;
    }
    .minus {
      border-radius: 8px 0px 0px 8px !important;

    }
    .add {
      border-radius: 0px 8px 8px 0px !important;

    }
    margin: 5px 0 5px 0;
`;

export const LoadingContainer = styled.div`
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;

    .svgContainer {
      padding: 1px 0 0 2px;
    }
`;