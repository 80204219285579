import React from 'react';
import { Button, Grid, Modal, Typography } from '..';
import TakeAway from '../../assets/images/banner_take_away.webp';
import { IconCircleAlert } from '~/assets/icons';
import { INuevaOrdenModalError  } from '~/interfaces';
import useHistorialOrdeneDetalle from '~/hooks/useHistorialOrdenesDetalle';

type Props = {
    handleClose: VoidFunction,
	open: boolean,
    width?: string,
	error?: INuevaOrdenModalError  | null
};
const NuevaOrdenModal : React.FC<Props> = ({ 
	handleClose,
	open,
	width,
	error
} : Props) => {

	const {
		handleVolverAPedir,
		loading
	} = useHistorialOrdeneDetalle(true, error || undefined);
	
	return (
		<Modal
			width={'100%'}
			maxWidth={width || '341px'}
			open={open}
			handleClose={handleClose}
			padding='0 10px 0 10px'
			action={<Grid container spacing='5px' padding='25px 15px 15px 15px'>
				<Grid item flex>
					{
						!error ? <Button fullWidth variant='underline' onClick={handleClose}>
						De acuerdo
						</Button> : error?.error === 'Problema al realizar el pago.' ? <Button loading={loading} onClick={handleVolverAPedir} fullWidth variant='contained'>
							Crear de nuevo
						</Button> : <></>
					}
					
				</Grid>  
			</Grid>}
			close
		>
			<Grid container justifyContent='center' padding='0 0 5px 0'>
				{error ? <IconCircleAlert width='80px' height='80px' color="red" /> : <img loading="lazy" draggable="false" width='70px' alt='Tu pedido' height='70px' src={TakeAway}></img>}
			</Grid>

			<Grid container justifyContent='center' textAlign='center'>
				<Typography variant='subtitle1bold'>{error?.error || '¡Estamos preparando tu pedido!'}</Typography>
			</Grid>
			{
				(!error || error?.subError) ? <Grid container justifyContent='center' textAlign='center'>
					<Typography variant='body2'>{error?.subError || 'Muy pronto lo vas a disfrutar'}</Typography>
				</Grid> : <></>
			}
			
			
			

		</Modal>
	);
};
export default (NuevaOrdenModal);