import React from 'react';
import useMercadoPago from '~/hooks/useMercadoPago';
import { FormContainer } from './styled';
import TextField from '../TextField';
import Grid from '../Grid';
import Typography from '../Typography';
import { RoundCardContainer } from '~/views/checkout/styled';
import Button from '../Button';
import { pricePrettify } from '~/utils';
import { CardFormDataType } from '~/interfaces';
type Props = {
	children ?: JSX.Element | JSX.Element[],
	nTotal: number,
	handleCardFormData:  (value: CardFormDataType | null) => void,
};

const MercadoPagoForm : React.FC<Props> = ({ children, nTotal,
	handleCardFormData,
} : Props) => {
	const {
		userInfo,
		nombres,
		handleNombres,
		cardTokenLoading,
		errorForm,
		email,
		handleEmail,
	} = useMercadoPago(nTotal,handleCardFormData);
	
	return (
		<FormContainer id={'form-checkout'}>
			<RoundCardContainer margin="0 0 9px 0">
				<span className='titleContainer'>
			Método de pago
				</span>
				{
					errorForm && <Typography variant='subtitle1bold' padding='5px 0 0 0' color='red'>Campos requeridos: </Typography>
				}
				<TextField value={nombres} onChange={handleNombres} margin='5px 0 0 0' fullWidth placeholder='Nombre' label='Nombre del dueño de la tarjeta' inputId={'form-checkout__cardholderName' }></TextField>
				<TextField /* value='3743 781877 55283' */ fullWidth helperText={'Ingresa los 16 números de tu tarjeta'} label='Número de la tarjeta' inputId={'form-checkout__cardNumber' }></TextField>
				<Grid container spacing='16px' padding='5px 0 0 0'>
					<Grid item flex>
						<TextField label='Fecha Vto.' inputId={'form-checkout__expirationDate' }></TextField>
					</Grid>
					<Grid item flex>
						<TextField label='CVV' inputId={'form-checkout__securityCode' }></TextField>
					</Grid>
				</Grid>
				<div className='hiddenContainer'>
					<select disabled id={'form-checkout__issuer' }></select>
					<select disabled id={'form-checkout__installments' }></select>
					<progress value="0" className="progress-bar">Cargando...</progress>
				</div>
				<div className={userInfo?.cCorreo ? 'hiddenContainer': undefined}>
					<TextField value={userInfo?.cCorreo || email} onChange={handleEmail} margin='5px 0 0 0' fullWidth placeholder='Email' label='Email' inputId={'form-checkout__cardholderEmail' }></TextField>
				</div>

			</RoundCardContainer>
			{children}
			<div className='footer'>
				<Button loading={cardTokenLoading} type="submit" fullWidth variant='contained-round'>{`Pagar ${pricePrettify(nTotal)}`}</Button>
			</div>
		</FormContainer>

	);
};
export default (MercadoPagoForm);