import React, { useState, createContext, useEffect, } from 'react';
import { useLocation } from 'react-router-dom';
import { CardFormDataType, mpDataType, PaymentProps} from '~/interfaces';
import { I3DinitializationStatus } from '../interfaces/index';

export type ContextProps = {
	cardFormData: CardFormDataType | null,
	setCardFormData: React.Dispatch<React.SetStateAction<CardFormDataType | null>>,
	handleCardFormData: (value: CardFormDataType | null) => void,
	total: number | null,
	settotal: React.Dispatch<React.SetStateAction<number | null>>,
	nivelCheckoutId: number | null,
	setNivelCheckoutId: React.Dispatch<React.SetStateAction<number | null>>,
	email: string,
	setEmail: React.Dispatch<React.SetStateAction<string>>,
	mpData: mpDataType | undefined,
	guardarTarjetaCheck: boolean,
	handleGuardarTarjetaCheck: VoidFunction,
	body3D: I3DinitializationStatus | null,
	handleBody3D: (value: I3DinitializationStatus | null) => void,
	setMpData: React.Dispatch<React.SetStateAction<mpDataType | undefined>>,
	config: Partial<PaymentProps['customization']>,
	handleConfig: (value: PaymentProps['customization']) => void
};
type PropsChildren = {
    children: JSX.Element | JSX.Element[],
};
export const CheckoutContext = createContext({} as ContextProps);

// Se manejan estados globales de la info de usuario
export const CheckoutProvider = ({children}: PropsChildren) => {
	const location = useLocation();
	const path = location.pathname;
	const pathMatches = path?.split('/')?.[2];
	
	const [cardFormData, setCardFormData] = useState<CardFormDataType | null>(null);
	const [email, setEmail] = useState('');


	const handleCardFormData = (value: CardFormDataType | null) => {
		setCardFormData(value);
	};

	const [total, settotal] = useState<number | null>(null);
	// PARA USAR EL FORMULARIO DE MERCADO PAGO Y QUE NO SE PERDIERA CON LOS STATUS DE REACT DEBÍA DE SUBIR EL CHECKOUT A NIVEL DEL GENERAL LAYOUT Y ESTE CONTEXT ES PARA COMUNICARME CON ESE CHECKOUT DESDE EL LAYOUT
	const [nivelCheckoutId, setNivelCheckoutId] = useState<number | null>(pathMatches ? parseInt(pathMatches) : null);

	useEffect(() => {
		setNivelCheckoutId(pathMatches ? parseInt(pathMatches) : null);
	}, [path]);

	const [mpData, setMpData] = useState<mpDataType | undefined>(undefined);

	const [guardarTarjetaCheck, setGuardarTarjetaCheck] = useState<boolean>(true);

	const handleGuardarTarjetaCheck = () => {
		setGuardarTarjetaCheck(!guardarTarjetaCheck);
	};

	const [body3D, setBody3D] = useState<I3DinitializationStatus | null>(null);
	
	const handleBody3D = (value: I3DinitializationStatus | null) => {
		setBody3D(value);
	};

	const [config, setConfig] = useState<Partial<PaymentProps['customization']>>({});

	const handleConfig = (value: PaymentProps['customization']) => {
		setConfig(value);
	};

	return <CheckoutContext.Provider
		value={{
			cardFormData,
			setCardFormData,
			setMpData,
			handleCardFormData,
			total,
			settotal,
			nivelCheckoutId: nivelCheckoutId || pathMatches ? parseInt(pathMatches) : null,
			mpData,
			setNivelCheckoutId,
			email,
			setEmail,
			guardarTarjetaCheck,
			handleGuardarTarjetaCheck,
			body3D,
			handleBody3D,
			config,
			handleConfig,
		}}>
		{children}
	</CheckoutContext.Provider>;
};

export default (CheckoutProvider);
