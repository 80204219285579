import React from 'react';
import { LocationOnIcon } from '~/assets/icons';
import { OpcionesEntregaModal, Typography } from '~/components';
import Grid from '~/components/Grid';
import useOpcionesEntrega from '~/hooks/useOpcionesEntrega';
import { getUtcDate } from '~/utils';
import { firstLetterUpperCase } from '~/utils/validations';
import { DirectionButton } from './styled';

type Props = {
	handleCloseSearch?: VoidFunction,
	xs: boolean,
	hide?: boolean,
	nivelesTiendaId?: number
};

const OpcionesEntrega : React.FC<Props> = ({ handleCloseSearch, xs, hide, nivelesTiendaId }: Props) => {
	const {
		activePosition,
		handleAddDireccion,
		tipoStep,
		getFunctionButton,
		nuevaDireccion,
		loadingButton,
		handleProgramarRecoleccion,
		dataSucursales,
		sucursal,
		handleSucursal,
		dataCiudades,
		ciudad,
		handleCiudad,
		loadingSucursales,
		getDisabledButton,
		handleProgramarEnvio,
		fechaRecoleccion,
		handleFechaRecoleccion,
		horas,
		horaRecoleccion,
		handleHoraRecoleccion,
		detalleEnvio,
		today,
		handleCancelar,
		handleGoToModificarDomicilio,
		dataDirecciones,
		currentDireccion,
		handleCurrentDireccion,
		handleReset,
		open,
		handleOpen,
		carrito,
		handleClose,
		handleEntregarAhora,
		errorDate,
	} = useOpcionesEntrega(nivelesTiendaId);
	
	if(hide){
		return <></>;
	} else {
		return (
			<>
				<Grid item flex={xs ? undefined : true} maxwidth={xs ? '260px' : '100%'} container direction='column'>
					<DirectionButton onClick={()=>{
						handleOpen();
						if(handleCloseSearch){
							handleCloseSearch();
						}
					}}>
						<Grid item container justifyContent='center' className='timeContainer'>
							<Typography padding='0 0 1px 0' variant='caption' color='primary'>
								{detalleEnvio?.bHoraPendiente ? 'Pendiente' : (detalleEnvio?.dFechaAgendada && detalleEnvio?.cValorHoraAgendada && !detalleEnvio?.cValorHoraAgendada.includes('ahora') ? `${firstLetterUpperCase(
									getUtcDate(detalleEnvio?.dFechaAgendada, 'MMMM DD'))} ${detalleEnvio?.cValorHoraAgendada ? `- ${detalleEnvio?.cValorHoraAgendada}` : '- Ahora'}` : detalleEnvio?.cValorHoraAgendada || (detalleEnvio?.nSucursal?'Recoger ahora' : 'Entregar ahora'))}
							</Typography>
						</Grid>    
						<Grid item container>
							<Grid width='19px' container justifyContent='center' alignItems='center' item padding='0 5px 0 0'>
								<LocationOnIcon color='primary' style={{transform:'scale(1)', width: '14px', height: '16px'}} />
							</Grid>
							<Grid item flex className='spanContainer'>
								<span >{detalleEnvio?.nSucursal ?`Sucursal ${detalleEnvio?.cSucursal}` : detalleEnvio?.nDireccion ? (`${detalleEnvio?.cCalle || ''} ${detalleEnvio?.cNumeroExterior ? `#${detalleEnvio?.cNumeroExterior}` : ''}` ) : 'Ingresa tu ubicación'}</span>
							</Grid>
						</Grid>   

						{/* <Divider margin='0 10px 0 7px' vertical/> */}
					</DirectionButton>
				</Grid>
				<OpcionesEntregaModal 
					today={today}
					errorDate={errorDate}
					handleEntregarAhora={handleEntregarAhora}
					detalleEnvio={detalleEnvio}
					activePosition={activePosition}
					handleAddDireccion={handleAddDireccion}
					tipoStep={tipoStep}
					getFunctionButton={getFunctionButton}
					nuevaDireccion={nuevaDireccion}
					loadingButton={loadingButton}
					handleProgramarRecoleccion={handleProgramarRecoleccion}
					dataSucursales={dataSucursales}
					sucursal={sucursal}
					handleSucursal={handleSucursal}
					dataCiudades={dataCiudades}
					carrito={carrito}
					ciudad={ciudad}
					handleCiudad={handleCiudad}
					loadingSucursales={loadingSucursales}
					xs={xs}
					getDisabledButton={getDisabledButton}
					handleProgramarEnvio={handleProgramarEnvio}
					fechaRecoleccion={fechaRecoleccion}
					handleFechaRecoleccion={handleFechaRecoleccion}
					horas={horas}
					horaRecoleccion={horaRecoleccion}
					handleHoraRecoleccion={handleHoraRecoleccion}
					handleCancelar={handleCancelar}
					handleGoToModificarDomicilio={handleGoToModificarDomicilio}
					dataDirecciones={dataDirecciones}
					currentDireccion={currentDireccion}
					handleCurrentDireccion={handleCurrentDireccion}
					handleReset={handleReset}
					open={open} 
					handleClose={handleClose} 
				/>
			</>
		);
	}
};

export default (OpcionesEntrega);