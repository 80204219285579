import React, {useEffect} from 'react';
import {AlertContainer, BodyContainer, GreenContainer, ShadowContainer} from './styled';
import Typography from '../Typography';
import { CheckIcon, IconCircleAlert } from '~/assets/icons';
import Grid from '../Grid';
import CloseButton from '../CloseButton';
type Props = {
  bottom?: string;
  open: boolean, handleClose: ()=>void, subtitle: string, error: boolean
  };
const AlertComponent: React.FC<Props> = ({open, handleClose, subtitle, error, bottom}: Props) => {
	useEffect(() => {
		if(open === true){
			setTimeout(()=>handleClose(), 5000);
		}
	}, [open]);
	return <AlertContainer bottom={bottom} open={open}>
		<ShadowContainer>
			<GreenContainer error={error}>
				{
					error ? <IconCircleAlert color='white'></IconCircleAlert> :  <CheckIcon color='white' />
				}      
			</GreenContainer>
			<BodyContainer>
				<Grid item flex>
					<Typography variant='caption'>
						{subtitle || ''}
					</Typography>
				</Grid>
				<CloseButton onClick={handleClose} />
			</BodyContainer>
		</ShadowContainer>
	</AlertContainer>;
};

export default (AlertComponent);
