import styled from 'styled-components';

export interface ButtonProps {
  padding?: string;
}

export const DirectionButton = styled.button<ButtonProps>`
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    flex-direction: column;
    @media (max-width: 650px) {
        flex: 1;
    }
    .spanContainer {
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap !important;
        width: 100%;
        text-align: left;
        max-width: 260px;
        span {
            font-size: ${props => props.theme.text.size.subtitle1};
            font-weight: ${props => props.theme.text.weight.medium};
            font-family: ${props =>props.theme.text.font.primary};
            color: ${props => props.theme.colors.primary};
            
        }

        @media (max-width: 650px) {
            max-width: calc(100vw - 100px);

        }

    }
    
    .timeContainer {
        text-overflow:ellipsis;
        overflow:hidden;
        white-space:nowrap !important;
        flex: 0 0 auto;
    }
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: ${props => props.theme.colors.primary}
    }
`;
