import React, { useContext, useState } from 'react';
import { Modal, SideBarContainer } from './styled';
import { useNavigate } from 'react-router-dom';
import Title from '../Title';
import { InivelesTienda, UserInfoType } from '~/interfaces';
import Grid from '../Grid';
import { EmpleadoIcon, HeartIcon, LocationOnIcon, LogOutIcon, ReciptIcon, ReciptLongIcon, SellIcon, StoreIcon, SupportIcon, TrophyIcon } from '~/assets/icons';
import Profile from '../Profile';
import { MenuItemChevron, ProfileCards, Typography, MenuItem, Divider, CloseButton, Button, WarningModal } from '..';
import _ from 'lodash';
import { PATHS } from '~/constants/paths';
import { useDialog } from '~/hooks';
import caraPana from '~/assets/images/carapana.webp';
import { AlertToastContext } from '~/context/alertToastContext';
import { handleGoToFacturacion, handleGoToPanaNews } from '~/utils';
type Props = {
	
	open: boolean,
	handleClose: VoidFunction,
	userInfo: UserInfoType | null,
	nivelesTienda: InivelesTienda[],
	handleLogout: (onFinish?: VoidFunction) => void

};

type PropsSideBarContainer = {
	handleClose: VoidFunction,
	open: boolean,
	children: JSX.Element | JSX.Element[],
};

export const SideModalBarContainer : React.FC<PropsSideBarContainer> = ({ handleClose,
	open, children } : PropsSideBarContainer) => {
	return (
		<Modal onClick={handleClose} open={open}>
			{children}
		</Modal>
	);
};


const SideBar : React.FC<Props> = ({ userInfo, handleClose, open, nivelesTienda, handleLogout } : Props) => {
	const navigate = useNavigate();

	const SIDE_BAR_MENU = [
		{
			cNombre: 'Cupones y promociones',
			nValor: 1,
			path: '/cupones-promociones',
			invitado: false,
			icon: <SellIcon />
		},
		{
			cNombre: 'Historial de pedidos',
			nValor: 10,
			path: '/historial-de-pedidos',
			invitado: true,
			icon: <ReciptIcon />
		},
		{
			cNombre: 'Sucursales',
			nValor: 2,
			path: '/sucursales',
			icon: <StoreIcon />
		},
		{
			cNombre: 'Ayuda',
			nValor: 3,
			path: '/ayuda',
			icon: <SupportIcon />
		},
		{
			cNombre: 'Portal de facturación',
			nValor: 4,
			path: '/metodos-de-pago',
			icon: <ReciptLongIcon />,
			onclick: handleGoToFacturacion,
		},
		{
			cNombre: 'Dinámicas',
			nValor: 12,
			path: '/dinamicas',
			invitado: false,
			icon: <TrophyIcon />
		},
		{
			cNombre: 'Dinámicas',
			nValor: 13,
			path: '/inicia-sesion/Dinam',
			invitado: true,
			icon: <TrophyIcon />
		},
		{
			cNombre: 'Empleados',
			nValor: 15,
			icon: <EmpleadoIcon />,
			onclick: handleGoToPanaNews
		},
		{
			cNombre: 'Cerrar sesión',
			invitado: false,
			nValor: 5,
			onclick: () =>{handleOpenLogoutModal(); handleClose();},
			icon: <LogOutIcon />
		},
	];

	const handleGoLogin = () => {
		navigate(PATHS.login);
	};

	const handleGoCrearCuenta = () => {
		navigate(PATHS.crear_cuenta);
	};

	const {
		open: openLogoutModal,
		handleOpen: handleOpenLogoutModal,
		handleClose: handleCloseLogoutModal
	} = useDialog();

	const [loading, setLoading] = useState(false);
	const {showBlackAlert} = useContext(AlertToastContext);

	return (
		<>
		
			<WarningModal 
				width='341px'
				loading={loading}
				warningLabel={
					<>¿Seguro que desea <br/> cerrar sesión?</>
				}
				handleAcept={() => {
					setLoading(true);
					handleLogout(()=> {
						handleCloseLogoutModal();
						setLoading(false);
						showBlackAlert('Se ha cerrado sesión correctamente. ¡Hasta pronto!');
						navigate('/');
					});
				}}
				open={openLogoutModal} handleClose={handleCloseLogoutModal} />
			<SideModalBarContainer
				handleClose={handleClose}
				open={open}
			>
				<SideBarContainer showSideBar={open} onClick={(e)=>e.stopPropagation()}>
					<div className='body'>
						<Grid container>
							<CloseButton onClick={handleClose} />
						</Grid>
					
						<div className='body-side-bar'>
							<Grid container direction='column'>
							
								{
									userInfo?.invitado ? <>
										<Button onClick={handleGoLogin} margin="1.3rem 0 0.5rem 0" variant='contained-round'>
										Inicia sesión
										</Button>
										<Button onClick={handleGoCrearCuenta} variant='outlined-round'>
										Crear cuenta
										</Button>
									</> : <>
										<Grid container>
											<Grid alignItems='flex-start' item flex={7} container direction='column' padding='15px 0 0 4px'>
												<Typography padding='0 0 3px 0' variant='body2light'>Hola</Typography>
												<Title variant='h7'>{userInfo ? `${userInfo.cNombre} ${userInfo.cApellido_Paterno} ${userInfo.cApellido_Materno}` : ''}</Title>
											</Grid>
											<Grid item flex={3}>
												<Profile onClick={()=>navigate('/mi-usuario')} src={caraPana} edit></Profile>
											</Grid>
										</Grid>
								
										<Grid spacing='5px' justifyContent='space-between' container padding='15px 0 10px 0'>
											<ProfileCards onClick={()=>navigate('/favoritos')} title='Favoritos'>
												<HeartIcon style={{transform: 'scale(1.5)', height: '50px'}} color='primary'></HeartIcon>
											</ProfileCards>
											<ProfileCards onClick={()=>navigate('/mis-direcciones')} title='Mis direcciones'>
												<div style={{height: '35px', marginTop: '15px'}}>
													<LocationOnIcon color='primary' style={{transform: 'scale(1.4)'}} />

												</div>
											</ProfileCards>
											<ProfileCards onClick={()=>navigate('/historial-de-pedidos')} title='Historial de pedidos'>
												<div style={{height: '27px', marginTop: '23px'}}>

													<ReciptIcon style={{transform: 'scale(1.5)', height: '50px'}}  color='primary'></ReciptIcon>
												</div>

											</ProfileCards>
										</Grid>
										
									</>
								}
								<Divider margin='0.8rem 0 0.5rem 0'/>
								<Typography padding='5px 0 0 0' variant='body2light'>Tienda en Línea</Typography>
								{
									(nivelesTienda || []).map((item, i) => {
										return <MenuItemChevron key={i} onClick={() =>navigate(`/tienda/${item.nNivelTienda}`)} padding='10px 16px 10px 16px' 
										>
											<>
												{item.cDescripcion} 
												{
													item.bHorarioDisponible === 0 ? <>
														<br></br>
														<Typography 
															color='grey'
															variant='overline'>
												Fuera de horario - Programar para más tarde
														</Typography>
													
													</> : ''
												}
											</>
										</MenuItemChevron>;
									})
								}
								<Divider margin='0.8rem 0 0.5rem 0'/>
								<Typography padding='5px 0 0 0' variant='body2light'>Información</Typography>
								{
									_.filter(SIDE_BAR_MENU, o=>(o.invitado !== undefined ? (o.invitado === userInfo?.invitado): true)).map((item, i) => {
										return <MenuItem key={i} onClick={item.onclick ? () => item.onclick() : () =>navigate(item.path || '')}>
											<Grid container alignItems='center'>
												{item.icon}
												<Grid item flex padding='0 0 0 10px'>
													<Typography className='noselect' pointer variant='subtitle1bold'>
														{item.cNombre}
													</Typography>
												</Grid>
											</Grid>
										</MenuItem>;
									})
								}

							</Grid>
						

						
						</div>
					</div>
				</SideBarContainer>
			</SideModalBarContainer>
		</>
		
		
	
	);
};
export default (SideBar);