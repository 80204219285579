import styled, { css } from 'styled-components';

export interface CuponProps {
  margin?: string;
  color?: string;
  disabled?: boolean
}

export const Cupon = styled.div<CuponProps>`
    min-height: 56px;
    margin: ${props => props.margin};
   
    position: relative;
    box-sizing: border-box;
 
    .left {
        left: -11px;
    }
    .right {
        right: -11px;
    }
    overflow: hidden;
    .container {
        border: 1px solid ${props => props.theme.colors.borderGrey};
        background-color: ${props => props.theme.colors[props.color ? props.color : props.disabled ? 'backgroundCardGrey' : 'yellowCupon']};
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        flex: 1;
        padding: 8px 20px 8px 20px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
    }
    ${props => props.disabled ? css`
    span {
        color: ${props.theme.colors.disabledTextFieldGrey} !important;
    }
    ` : css`
    &:hover {
        cursor: pointer;
    }
    `}
`;


export const Circle = styled.div`
     height: 22px;
    width: 22px;
    border: 1px solid ${props => props.theme.colors.borderGrey};
    background-color: ${props => props.theme.colors.white};
    border-radius: 50%;
    display: inline-block;
    top: calc(50% - 11px);
    position: absolute;
    z-index: 10;
    
`;