import styled, { css } from 'styled-components';
import { productoHide } from '~/styles/keyframes';

export interface ProductCardContainerProps {
    disabled?: boolean;
    src?: string;
}

export const ProductCardContainer = styled.div<ProductCardContainerProps>`
    border-radius: 22px;
    border: 1px solid ${props => props.theme.colors.grey198};
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 172px;
    height: 180px;
    overflow: hidden;
    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.disabledCardBg};
    ` : css`
        cursor: pointer;
        background-color: ${props.theme.colors.white};
    `}
    .labelContainer {
      min-height: 81px;
      position: relative;
    }
    .paragraphContainer {
      text-overflow:ellipsis;
      overflow:hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      max-height: 36px;
    }
`;
export interface ProductCardContainerProps {
    disabled?: boolean;
    hide?: boolean;
}
export const ViewIconContainer = styled.div`
    position: absolute;
    z-index: 5;
    display: flex;
    width:  100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    top: 0px;
    padding: 5px 5px 0 5px;
    box-sizing: border-box;

`;
export const ProductCardContainerMain = styled.div<ProductCardContainerProps>`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    ${props => props.hide ? css`
    animation-name: ${productoHide};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    ` : null}
    transition: display 0.3s ease;
    ${props => props.disabled ? null : css`
    &:hover{
            cursor: pointer;
    }
    `}

`;
export const ImgContainer = styled.img<ProductCardContainerProps>`

    min-height: 220px;
    width: 100%;
    border-radius: 22px 22px 0px 0px;
    border-bottom: 1px solid ${props => props.theme.colors.borderLightGrey};
`;



export const StarContainer = styled.div`
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 12px;
`;


export const ViewIcon = styled.div`
   border-radius: 0px 0px 30px 30px;
   border: 1px solid ${props => props.theme.colors.borderLightGrey};
`;

export const PriceContainer = styled.div`
    aspect-ratio: 1;
    border-radius: 50%; 
    padding: 4px;
    display: grid;
    place-items: center;
    background-color: ${props => props.theme.colors.primaryYellow};
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 24px;
    height: 24px;
    .div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      
`;



export interface YellowTagProps {
  left?: string;
  top?: string;
  right?: string;
  center?: boolean;
}

export const YellowTag = styled.div<YellowTagProps>`
    padding: 3px 5px 3px 5px;
    background-color: ${props => props.theme.colors.yellow80};
    font-family: ${props =>props.theme.text.font.primary};
    position: absolute;
    top: ${props => props.top || '-24px'};
    border-radius: 0px 4px 4px 0px;
    left: ${props => props.center ? '50%' : props.left || '0px'};
    right: ${props => props.right};
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.bold};
    transform: ${props=>props.center && 'translate(-50%, -50%)'};
    z-index: 10;

      
`;

export const RedTag = styled.div<YellowTagProps>`
    padding: 3px 5px 3px 5px;
    background-color: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
    font-family: ${props =>props.theme.text.font.primary};
    position: absolute;
    top: ${props => props.top || '-24px'};
    border-radius: 0px 4px 4px 0px;
    left: ${props => props.center ? '50%' : props.left || '0px'};
    right: ${props => props.right};
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.bold};
    transform: ${props=>props.center && 'translate(-50%, -50%)'};
    z-index: 10;

      
`;