import styled, { css } from 'styled-components';

export interface Props {
    variant?: string,
    padding?: string,
    margin?: string,
    color?: string,
    pointer?: boolean;
    textAlign?: string;
}

const Title = styled.span<Props>`
  padding: ${props =>props.padding};
  margin: ${props =>props.margin};
  font-family: ${props =>props.theme.text.font.primary};
  color: ${props => props.theme.colors[props.color ? props.color: 'black']};
  cursor: ${props =>props.pointer ? 'pointer' : null};
  ${props => props.textAlign && css`text-align: ${props.textAlign};`}
  line-height: 1.3;

  ${props => {
		switch (props.variant) {
		case 'h1':
			return css`
          font-weight: ${props.theme.text.weight.light};
          font-size: ${props.theme.text.size.h1};
        `;
		case 'h2':
			return css`
          font-size: ${props.theme.text.size.h2};
          font-weight: ${props.theme.text.weight.light};
        `;
		case 'h3':
			return css`
          font-size: ${props.theme.text.size.h3};
          font-weight: ${props.theme.text.weight.regular};
        `;
		case 'h4':
			return css`
          font-size: ${props.theme.text.size.h4};
          font-weight: ${props.theme.text.weight.bold};
        `;
		case 'h5':
			return css`
          font-size: ${props.theme.text.size.h5};
          font-weight: ${props.theme.text.weight.bold};
        `;
		case 'h5-medium':
			return css`
          font-size: ${props.theme.text.size.h5};
          font-weight: ${props.theme.text.weight.medium};
        `;
		case 'h5-semiBold':
			return css`
          font-size: ${props.theme.text.size.h5};
          font-weight: ${props.theme.text.weight.semiBold};
        `;
		case 'h6':
			return css`
          font-size: ${props.theme.text.size.h6};
          font-weight: ${props.theme.text.weight.bold};

        `;
		case 'h7':
			return css`
          font-size: ${props.theme.text.size.h7};
          font-weight: ${props.theme.text.weight.bold};

        `;
		case 'h8':
			return css`
          font-size: ${props.theme.text.size.h8};
          font-weight: ${props.theme.text.weight.bold};

        `;
		case 'h10':
			return css`
          font-size: ${props.theme.text.size.h10};
          font-weight: ${props.theme.text.weight.bold};

        `;
		}
	}}
`;


export default Title;