import styled from 'styled-components';
import { TextFieldProps } from '../TextField/styled';

export interface ProductTextFieldProps {
  disabled?: boolean;
}

export const ProductTextFieldContainer = styled.div<ProductTextFieldProps>`
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 22px;
    input[type='number'] {
        -moz-appearance:textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    img {
      width: 15px;
      height: 15px;
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px 0 10px;
    }
    .minus {
      background-color: ${props => props.theme.colors.white};
      border-radius: 22px 0px 0px 22px !important;

    }
    .bottom {
      padding-bottom: 4px;

    }
    .add {
      background-color: ${props => props.theme.colors.white};
      border-radius: 0px 22px 22px 0px !important;

    }
    .hide {
      background-color: transparent !important;
      border: none !important;
      ${props => props.disabled ? '' : 'cursor: pointer;'}
      

    }
    
`;
export const TextFieldContainerInput = styled.div<TextFieldProps>`
    display: flex;
    min-height: 56px;
    font-family: ${props => props.theme.text.font.primary};
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.white};
    max-width: 50px;
`;

export const TextFieldInput = styled.input<TextFieldProps>`
    padding: 10px 12px 10px 12px;
    border: none !important;
    max-width: 50px;
    flex: auto;
    overflow: hidden;
    font-family: ${props =>props.theme.text.font.primary};
    text-align: center;
    font-weight: ${props => props.theme.text.weight.bold};
    box-sizing: border-box;

    &:focus {
      outline: none;
    }
`;
