import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const useGeneralLayout = () => {

	const location = useLocation();
	const path = location.pathname;
	
	const isBuscador = path.includes('/buscador/');
	
	const apiUrl = import.meta.env.VITE_API_URL;


	
	// Para abrir o cerrar la Sidebar
	const [openSideBar, setOpenSideBar] = useState(false);
	const handleOpenSideBar = () => {
		setOpenSideBar(true);
	};
	const handleCloseSideBar = () => {
		setOpenSideBar(false);
	};

	useEffect(() => {
		handleCloseSideBar();
	}, [path]);
	

	return {
		openSideBar,
		handleOpenSideBar,
		handleCloseSideBar,
		isBuscador,
		apiUrl
	};
};

export default useGeneralLayout;

