import React, {useEffect} from 'react';
import {AlertContainer, BodyContainer, ShadowContainer} from './styled';
import Typography from '../Typography';
import Grid from '../Grid';

type Props = {
	bottom?: string;
	open: boolean, 
	handleClose: () => void, 
	subtitle: string,
};

const BlackAlert: React.FC<Props> = ({open, handleClose, subtitle, bottom}: Props) => {
	useEffect(() => {
		setTimeout(()=>handleClose(), 5000);
	}, [open]);
	return <AlertContainer bottom={bottom} open={open}>
		<ShadowContainer>
			<BodyContainer>
				<Grid item flex>
					<Typography color='white' variant='subtitle1'>
						{subtitle || ''}
					</Typography>
				</Grid>
			</BodyContainer>
		</ShadowContainer>
	</AlertContainer>;
};

export default (BlackAlert);
