import { RoundCardContainer } from '~/views/checkout/styled';
import Typography from '../Typography';
import Switch from '../Switch';
import Grid from '../Grid';

type props = {
	toggle?: () => void, 
	checked?: boolean, 
	title: string, 
	description: string
}

export const CookiesCard = ({ toggle, checked, title, description }: props) => {
	return (
		<RoundCardContainer padding='16px'>
			<Grid container borderbottom justifyContent='space-between' padding='0px 0px 8px 0px' alignItems='center'>
				<Typography variant='subtitle1bold'>{title}</Typography>

				{ typeof checked === 'boolean' && toggle && <Switch checked={checked} onClick={toggle} /> }
			</Grid>

			<Typography margin='8px 0px 0px 0px' variant='subtitle2'>{description}</Typography>
		</RoundCardContainer>
	);
};
