import styled from 'styled-components';

export interface ControlledSpinnerContainerProps {
  padding?: string;
}

export const ControlledSpinnerContainer = styled.div<ControlledSpinnerContainerProps>`
  
    width: 70px;
    height: 70px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;