import styled, { keyframes, css } from 'styled-components';
import { CheckboxContainerProps } from '../CheckBox/styled';
import { productoHide } from '~/styles/keyframes';

export interface ProductCardProps {
    disabled?: boolean;
}

export const ProductCardContainer = styled.div<ProductCardProps>`
    border-radius: 22px;
    border: 1px solid ${props => props.theme.colors.borderGrey};
    position: relative;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    max-width: 341px;
    height: 400px;
    .labelContainer {
      border-top: 1px solid ${props => props.theme.colors.borderLightGrey};
      height: 208px;
      position: relative;
    }
    .paragraphContainer {
      text-overflow:ellipsis;
      overflow:hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
      max-height: 58px;
      box-sizing: border-box;
    }

    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.disabledCardBg};
    ` : css`
        background-color: ${props.theme.colors.white};
    `}

`;
export interface ProductCardContainerProps {
    hide?: boolean;
    disabled?: boolean;
}

const check = keyframes`
from {
    transform: scale(0.9);
    display: none;
    opacity: 0;
}
to {
    opacity: 1;
    display: block;
    transform: scale(1.0);
}
`;
const unCheck = keyframes`
from {
    opacity: 1;    
    transform: scale(1.0);
    display: block;
}
to {
    transform: scale(0.9);
    opacity: 0;    
    display: none;
}
`;
export const ViewIconContainer = styled.div<CheckboxContainerProps>`
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: flex-end;
    width:  100%;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    top: 0px;
    padding: 10px 10px 0 0;
    box-sizing: border-box;
    .icon{
        position: relative;
        width: 24px;
        height: 24px;
    }
    .check {
        position: absolute;
    }
        
    .uncheck {
        position: absolute;
    }

    ${props => props.checked === true ? css`
        .check {
            animation: ${check} 0.2s forwards;
        }

        .uncheck {
            animation: ${unCheck} 0.2s forwards;
        }
    
    ` : props.checked === false ? css`
        .check {
            animation: ${unCheck} 0.2s forwards;
        }

        .uncheck {
            animation: ${check} 0.2s forwards;
        }
    ` : css`
        .check {
            opacity: 0;
        }

        .uncheck {
            opacity: 1;
        }
    `}

`;
export const ProductCardContainerMain = styled.div<ProductCardContainerProps>`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: 341px;
    margin-bottom: 15px;
    justify-content: center;
    ${props => props.hide ? css`
    animation-name: ${productoHide};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    ` : null}
    transition: display 0.3s ease;
    ${props => props.disabled ? null : css`
    &:hover{
            cursor: pointer;
    }
    `}


`;
export const ImgContainer = styled.img<ProductCardContainerProps>`

    min-height: 190px;
    width: 100%;
    border-radius: 22px 22px 0px 0px;
    
`;



export const StarContainer = styled.div`
    position: absolute;
    z-index: 10;
    left: 20px;
    top: 12px;
`;


export const ViewIcon = styled.div`
   border-radius: 0px 0px 30px 30px;
   border: 1px solid ${props => props.theme.colors.borderLightGrey};
`;

export interface PopularTagProps {
    disabled?: boolean;
    show?: boolean;
}

export const PopularTag = styled.div<PopularTagProps>`
    background-color: ${props => props.theme.colors[props.disabled ? 'disabledFontGrey' : 'red']};
    border-radius: 4px;
    width: ${props => props.disabled ? '100px' : '58px'};
    height: 23px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    visibility: ${props => props.show ? 'visible' : 'hidden'};

`;

export const DiscountTag = styled.div<PopularTagProps>`
    padding: 2px 5px 3px 5px;
    font-family: ${props =>props.theme.text.font.primary};
    border-radius: 0px 4px 4px 0px;
    font-size: ${props => props.theme.text.size.caption};
    box-sizing: border-box;
    height: 23px;
    z-index: 10;
    white-space:nowrap !important;
    color: ${props => props.theme.colors.white};
      
    ${props => props.disabled ? css`
        background-color: ${props.theme.colors.disabledFontGrey};
    ` : css`
        font-weight: ${props.theme.text.weight.bold};
        background-color: ${props.theme.colors.green80};
    `}
`;

export const YellowTag = styled.div`
    padding: 2px 5px 0px 5px;
    background-color: ${props => props.theme.colors.yellow80};
    font-family: ${props =>props.theme.text.font.primary};
    border-radius: 0px 4px 4px 0px;
    font-size: ${props => props.theme.text.size.caption};
    font-weight: ${props => props.theme.text.weight.bold};
    height: 23px;
    z-index: 10;
    box-sizing: border-box;
    white-space:nowrap !important;

      
`;

export interface TagsContainerProps {
    padding?: string;
  }
  
export const TagsContainer = styled.div<TagsContainerProps>`
      position: absolute;
      bottom: 0;
      left: 0;
  `;