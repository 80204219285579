import React, { useState, createContext, useEffect, useContext, } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '~/hooks';
import { IComplementoModalStatus, INuevaOrdenModalError , InivelesTienda } from '~/interfaces';
import { CookiesContext } from './cookiesContext';

export type GeneralLayoutContextProps = {
	showBack: null | string,
	handleShowBack: (value: string, text?: string, titleValue?: string, showSideBarIcon?: boolean, height?: string | null | boolean) => void,
	handleGoBack: VoidFunction,
	handleResetShowBack: VoidFunction,
	nivelesTienda: InivelesTienda[] | undefined,
	backButtonContent: string,
	nivelesTiendaId: number,
	handleNivel: (value: number) => void,
	handleReset: VoidFunction,
	title: string,
	showSideBarIcon: boolean,
	xs: boolean,
	xsCarrito: boolean,
	responsiveHeight: string | null | boolean,
	onlyTextfield: boolean,
	mainValue: string,
	setMainValue: React.Dispatch<React.SetStateAction<string>>,
	complementoModalStatus: IComplementoModalStatus,
	handleComplementoModalStatus: (value?: IComplementoModalStatus | undefined) => void,
	showTopbar: boolean,
	setshowTopbar: React.Dispatch<React.SetStateAction<boolean>>,
	nuevaOrdenModalOpen: boolean,
	setNuevaOrdenModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	nuevaOrdenModalError: INuevaOrdenModalError  | null,
	setNuevaOrdenModalError: React.Dispatch<React.SetStateAction<INuevaOrdenModalError  | null>>,
	handleCloseNuevaOrdenModalOpen: VoidFunction
	path: string,
	navigate: NavigateFunction,
	warningOpcionesEntregaModal: boolean,
	setWarningOpcionesEntregaModal: React.Dispatch<React.SetStateAction<boolean>>,
};
type PropsChildren = {
    children: JSX.Element | JSX.Element[],
};

export const GeneralLayoutContext = createContext({} as GeneralLayoutContextProps);

export const GeneralLayoutProvider = ({children}: PropsChildren) => {
	const navigate = useNavigate();

	const [showBack, setshowBack] = useState<null | string>(null);
	const [showTopbar, setshowTopbar] = useState<boolean>(true);
	
	const [backButtonContent, setBackButtonContent] = useState<string>('Volver');
	const [title, setTitle] = useState<string>('');
	const [responsiveHeight, setResponsiveHeight] = useState<string | null | boolean>(null);
	const [showSideBarIcon, setShowSideBarIcon] = useState<boolean>(false);
	
	const handleGoBack = () => {
		if(showBack){
			navigate(showBack);
		}
	};

	const handleResetShowBack = () => {
		setshowBack(null);
		setShowSideBarIcon(false);
		setResponsiveHeight(null);
	};


	const apiUrl = import.meta.env.VITE_API_URL;

	const {
		headersApi,
	} = useContext(CookiesContext);
	
	// Obtiene los niveles

	const [nivelesTienda, setNivelesTienda] = useState<InivelesTienda[] | undefined>(undefined);
	useEffect(() => {
		if(headersApi.Authorization){
			fetch(`${apiUrl}/APPWEB/ObtenerNivelesTienda`, {
				method: 'GET',
				headers: headersApi
			}).then((res) => res.json()).then((response) => {
				if(response?.Result[0]){
					setNivelesTienda(response?.Result);

				}
			}).catch((error) => {
				console.error(error, 'error');
			});
		}
			
	}, [headersApi.Authorization]);

	const [nivelesTiendaId, setNivelesTiendaId] = useState<number>(0);

	const handleReset = () => {
		setNivelesTiendaId(0);
	};

	const handleNivel = (value: number) => {
		setNivelesTiendaId(value);
	};


	const xs = useMediaQuery(650);
	const xsCarrito = useMediaQuery(865);

	
	const location = useLocation();
	const path = location.pathname;
	const onlyTextfield = !!(path && (path.includes('/categoria/') || path.includes('/buscador/')) && !xs);

	const handleShowBack = (value: string, text = 'Volver', titleValue= '', showSideBarIconValue= false, height: string | null | boolean= null) => {
		setshowBack(value);
		setBackButtonContent(text);
		setTitle(titleValue);
		setResponsiveHeight(height);
		setShowSideBarIcon(showSideBarIconValue || false);
		setshowTopbar(true);
	};

	const [mainValue, setMainValue] = useState('');



	const complementoModalStatusInitialState: IComplementoModalStatus = {
		nCategoriaComplemento: null,
		cImagenUrlCategoriaComplemento: null,
		nNivelTienda: null,
		open: false
	};
	const [complementoModalStatus, setComplementoModalStatus] = useState<IComplementoModalStatus>(complementoModalStatusInitialState);

	const handleComplementoModalStatus = (value = complementoModalStatusInitialState) => {
		setComplementoModalStatus(value);
	};

	const [nuevaOrdenModalOpen, setNuevaOrdenModalOpen] = useState<boolean>(false);
	const [nuevaOrdenModalError, setNuevaOrdenModalError] = useState<INuevaOrdenModalError  | null>(null);
	
	const handleCloseNuevaOrdenModalOpen = () => {
		setNuevaOrdenModalOpen(false);
	};

	const [warningOpcionesEntregaModal, setWarningOpcionesEntregaModal] = useState<boolean>(false);

	useEffect(() => {
		if(!path.includes('/buscador/')){
			setMainValue('');
		}
		setWarningOpcionesEntregaModal(false);
	}, [path]);

	return <GeneralLayoutContext.Provider
		value={{
			showBack,
			handleShowBack,
			handleGoBack,
			handleResetShowBack,
			nivelesTienda,
			backButtonContent,
			nivelesTiendaId,
			handleReset,
			handleNivel,
			title,
			showSideBarIcon,
			xs,
			xsCarrito,
			responsiveHeight,
			onlyTextfield,
			mainValue,
			setMainValue,
			complementoModalStatus,
			handleComplementoModalStatus,
			showTopbar,
			setshowTopbar,
			nuevaOrdenModalOpen,
			setNuevaOrdenModalOpen,
			path,
			nuevaOrdenModalError,
			setNuevaOrdenModalError,
			navigate,
			handleCloseNuevaOrdenModalOpen,
			warningOpcionesEntregaModal,
			setWarningOpcionesEntregaModal,
		}}>
		{children}
	</GeneralLayoutContext.Provider>;
};

export default (GeneralLayoutProvider);
