import React, { useContext, useEffect, useState } from 'react';
import { CardMedia, CheckBox, CloseButton, Grid, Paragraph, ProductTextField, TextFieldComment, Title, Typography } from '~/components';
import { getCdnUrl, getDetalleProductoDisabledButton, getTotalPrice, pricePrettify } from '~/utils';
import { ConfigProductoContainer, DetalleContainer, Footer, ImgContainer, ItemRadio, Tag, ScrollContainer, CloseContainer } from './styled';
import { CarritoTypes, ConfiguracionProducto, ErrorTextFieldType, handleModificaCarritoType } from '~/interfaces';
import ButtonAddToCart from '~/components/ButtonAddToCart';
import { GeneralLayoutContext } from '~/context/generalLayoutContext';
import ExtrasContainer from '../extrasContainer';
import { TIPO_ACCION_CARRITO_ANIADIR, TIPO_ACCION_CARRITO_EDITAR } from '~/constants';
import nombreTarjeta from '~/assets/images/nombre_tarjeta.webp';
import { CookiesContext } from '~/context/cookiesContext';
import _, { isNumber } from 'lodash';
import { getTotalPriceModificadores } from '../../../../utils/index';
import { PopularTag } from '~/components/ProductCard/styled';
import { IconCircleAlert } from '~/assets/icons';

type Props = {
	dataProducto: ConfiguracionProducto;
	apiUrl: string;
	handleCheckVariantes: (e: number, id: number, nTipoVariante: number) => void;
	handlePlus: VoidFunction;
	handleCantidad: (value: number | string) => void;
	cantidad: number | string;
	handleMinus: VoidFunction;
	handleAddExtras: (e: number, remove?: boolean | undefined) => void;
	handleCheckModificadores: (i: number, id: number, nTipoModificador: number, bOpcionMultiple: boolean, e: number) => void;
	handleModificaCarrito: handleModificaCarritoType;
	handleClose: VoidFunction;
	handleComentario: (value: string) => void | undefined;
	handlePanaBoxDestinatario: (e: string) => void
	handlePanaBoxRemitente: (e: string) => void
	handleAddComplementos: (e: number, remove?: boolean | undefined) => void;
	comentario: string;
	carritoLoading?: boolean;
	handleEdad: (value: number | string) => void;
	handleEdadPlus: () => void;
	handleEdadMinus: () => void;
	edad: number | string;
	carritoItem?: CarritoTypes;
	nombreDelFestejado: string;
	panaBoxDestinatario: string
	panaBoxRemitente: string
	handleNombreDelFestejado: (value: string) => void;
	openDetalleProducto?: boolean;
	handleRemove?: (item: CarritoTypes, handleModificaCarrito: handleModificaCarritoType, handleCloseDetalleProducto: () => void) => void;
	loadingRemove?: boolean;
	nombreCumpleaniero: string;
	handleNombreCumpleaniero: (value: string) => void;
	errorNombreDelFestejado: ErrorTextFieldType;
	errorNombreCumpleaniero: ErrorTextFieldType;
	errorPanaBoxDestinatario: ErrorTextFieldType;
	errorPanaBoxRemitente: ErrorTextFieldType;
	errorComentario: ErrorTextFieldType;
};

const DetalleProducto: React.FC<Props> = ({
	dataProducto,
	apiUrl,
	handleCheckVariantes,
	handlePlus,
	handleCantidad,
	cantidad,
	handleMinus,
	handleAddExtras,
	handleCheckModificadores,
	handleModificaCarrito,
	handleClose,
	handleComentario,
	comentario,
	carritoLoading,
	handleAddComplementos,
	carritoItem,
	nombreDelFestejado,
	handleNombreDelFestejado,
	handlePanaBoxDestinatario,
	handlePanaBoxRemitente,
	openDetalleProducto,
	handleRemove,
	loadingRemove,
	handleEdad,
	handleEdadPlus,
	handleEdadMinus,
	edad,
	nombreCumpleaniero,
	panaBoxDestinatario,
	panaBoxRemitente,
	handleNombreCumpleaniero,
	errorNombreDelFestejado,
	errorNombreCumpleaniero,
	errorComentario,
	errorPanaBoxDestinatario,
	errorPanaBoxRemitente
}: Props) => {
	const cantidadHelper = isNumber(cantidad) ? cantidad : 0;
	const helperPrice = getTotalPrice(dataProducto, cantidadHelper);
	const helperPriceModificadores = getTotalPriceModificadores(dataProducto, cantidadHelper);

	const { xsCarrito, handleComplementoModalStatus } = useContext(GeneralLayoutContext);

	const { dataCarrito } = useContext(CookiesContext);

	const [carritoData, setCarritoData] = useState(dataCarrito);
	useEffect(() => {
		if (openDetalleProducto === true) {
			setCarritoData(dataCarrito);
		}
	}, [openDetalleProducto]);
	const producto = dataProducto?.producto;
	const handleDoneAddCarrito = () => {
		if (carritoItem) {
			handleClose();
		} else {
			handleClose();
			// nCategoriaComplemento
			// cImagenUrlCategoriaComplemento

			if (!_.find(carritoData, (o) => o.nValor === dataProducto.nivelProducto) && _.filter(dataProducto.dataComplementos, (o) => o.nCantidad)?.length === 0 && producto.nCategoriaComplemento && dataProducto.nivelProducto) {
				handleComplementoModalStatus({
					nNivelTienda: dataProducto.nivelProducto,
					open: true,
					cImagenUrlCategoriaComplemento: producto.cImagenUrlCategoriaComplemento,
					nCategoriaComplemento: producto.nCategoriaComplemento,
				});
			}
		}
	};
	const disabled = producto?.bNuevaDisponibilidad !== undefined ? !producto?.bNuevaDisponibilidad : false;

	const footerItem = (
		<Footer>
			<ProductTextField
				handleRemove={carritoItem && handleRemove ? () => handleRemove(carritoItem, handleModificaCarrito, handleClose) : undefined}
				removeLoading={loadingRemove}
				handleCantidad={handleCantidad}
				handleMinus={handleMinus}
				handlePlus={handlePlus}
				cantidad={cantidad}
				className={''}
			/>

			<ButtonAddToCart
				loading={carritoLoading && !loadingRemove}
				onClick={() =>
					handleModificaCarrito(
						carritoItem ? TIPO_ACCION_CARRITO_EDITAR : TIPO_ACCION_CARRITO_ANIADIR,
						producto.nVProducto,
						producto.nProductoSIP,
						cantidadHelper,
						dataProducto,
						carritoItem?.nPedido,
						undefined,
						undefined,
						handleDoneAddCarrito,
						undefined,
						comentario,
						nombreDelFestejado,
						edad,
						nombreCumpleaniero,
						panaBoxDestinatario,
						panaBoxRemitente
					)
				}
				disabled={getDetalleProductoDisabledButton(cantidadHelper, dataProducto, edad, errorNombreDelFestejado, errorNombreCumpleaniero, errorComentario, nombreCumpleaniero, errorPanaBoxRemitente, panaBoxRemitente, errorPanaBoxDestinatario, panaBoxDestinatario) || loadingRemove || disabled}
				leftText={carritoItem ? 'Modificar ' : 'Añadir'}
				rightText={helperPriceModificadores}
			/>
		</Footer>
	);

	const obligatorioTag = <Tag>Obligatorio</Tag>;

	const precioEspecial = isNumber(producto?.bNuevoPrecioEspecial) ? !!producto?.bNuevoPrecioEspecial : !!producto?.bPrecioEspecial;

	const precioBase = isNumber(producto?.nNuevo_Precio_Base_Original) && precioEspecial ? producto?.nNuevo_Precio_Base_Original : producto?.nPrecio_Base_Original;
	const noDisponibleTag = (
		<PopularTag show disabled>
			<Typography color="white" variant="caption">
				No disponible
			</Typography>
		</PopularTag>
	);

	return (
		<>
			{xsCarrito && (
				<CloseContainer>
					<CloseButton onClick={handleClose} />
				</CloseContainer>
			)}

			<DetalleContainer>
				{!xsCarrito && (
					<CloseContainer>
						<CloseButton onClick={handleClose} />
					</CloseContainer>
				)}
				<ImgContainer>
					<CardMedia minHeight="30px" height="297px" alt={dataProducto?.producto?.cDescripcion} src={getCdnUrl(dataProducto?.producto.cImagenUrl)} />

					<Grid item flex direction="column" container padding="20px 0 0 0">
						{disabled && noDisponibleTag}

						<Title className="item" variant="h5">
							{dataProducto?.producto.cDescripcion}
						</Title>

						<Typography className="item" color={precioEspecial ? 'green' : 'black'} padding="18px 0 5px 0" variant="subtitle1bold">
							{helperPrice || producto?.nPrecio_Base}

							{!!(precioEspecial && isNumber(precioBase)) && (
								<Typography padding="0 5px 0 0" variant="lineSubtitle1">
									{pricePrettify(precioBase)}
								</Typography>
							)}
						</Typography>

						{producto.cEtiquetaPromocion ? (
							<Grid padding={!xsCarrito ? '0 16px 14px 16px' : undefined} alignItems="center" container>
								<Grid item container width="28px" alignItems="center">
									<IconCircleAlert style={{ transform: 'scale(0.9)' }} color="ocher" />
								</Grid>
								<Grid item flex container>
									<Typography variant="caption" color="ocher">
										{producto.cEtiquetaPromocion}
									</Typography>
								</Grid>
							</Grid>
						) : null}

						<Paragraph padding="17px 0 0 0" className="lastItem" color="titleBlack" variant="body1">
							{dataProducto?.producto.cDetalle}
						</Paragraph>
					</Grid>
				</ImgContainer>

				<Grid padding={xsCarrito ? '0 25px 0 0' : undefined} container direction="column" item flex={xsCarrito ? 1 : undefined}>
					<ScrollContainer>
						{dataProducto.dataVariantesProducto.map((item, i) => {
							return (
								<ConfigProductoContainer key={i}>
									<Grid container className="header" direction="column">
										{obligatorioTag}
										<Title variant="h5">{item.cDescripcionVariante}</Title>
									</Grid>
									{item.jVariantes.map((variante, e) => {
										const apartado = `${item.nTipoVariante}-${variante.nOpcion}`;
										const noDisponible = dataProducto?.dataVariantesProducto?.length === 1 && dataProducto?.dataProductoVariantes?.[apartado]?.bDisponible === 0;
										const checked = item.checked ? item.checked === variante.nOpcion && variante.nTipoVariante === item.checkedTipoVariante : false;

										return (
											<ItemRadio checked={checked} key={e} disabled={noDisponible} onClick={noDisponible ? undefined : () => handleCheckVariantes(i, variante.nOpcion, item.nTipoVariante)}>
												<Grid padding="0 0 0 10px" flex item container alignItems="center">
													<Typography color={noDisponible ? 'disabledFontGrey' : 'black'} variant="body1" className="text">
														{variante.cDescripcion}
													</Typography>
													{noDisponible && (
														<Grid item margin="0 0 0 10px">
															{noDisponibleTag}
														</Grid>
													)}
												</Grid>

												<CheckBox disabled={noDisponible} radio={true} checked={checked} onClick={noDisponible ? undefined : () => handleCheckVariantes(i, variante.nOpcion, item.nTipoVariante)}></CheckBox>
											</ItemRadio>
										);
									})}
								</ConfigProductoContainer>
							);
						})}

						{dataProducto.dataModificadoresProducto.map((item, i) => {
							const isGreen = !!_.find(item.jModificadores, (o) => (o?.nProductoSIP || 0) >= 0 && (o?.nPrecio_Base || 0) > 0);
							const modificadoresSelected = _.filter(item.jModificadores, (a) => a.checked === true).length;
							const error =
								item.nCantidadMaxima > 1 || item.nCantidadMinima > 1
									? item.nCantidadMinima === 0
										? modificadoresSelected > item.nCantidadMaxima || (item.bObligatorio ? modificadoresSelected <= 0 : false)
										: modificadoresSelected > item.nCantidadMaxima || modificadoresSelected < item.nCantidadMinima
									: false;

							return (
								<ConfigProductoContainer margin={producto.bEdadCumpleanios || producto.bNombreCumpleanios ? '0 0 5px 0' : undefined} key={i}>
									<Grid container className="header" direction="column">
										{item.bObligatorio ? obligatorioTag : null}
										<Title variant="h5">{item.cDescripcionModificador}</Title>

										{(item.nCantidadMaxima > 1 || item.nCantidadMinima > 1) && (
											<Typography color={error ? 'red' : undefined} variant="body2">
												{item.nCantidadMinima === 0
													? `Selecciona máximo ${item.nCantidadMaxima}.`
													: item.nCantidadMaxima === item.nCantidadMinima
														? `Selecciona ${item.nCantidadMaxima}.`
														: `Selecciona al menos ${item.nCantidadMinima}${item.nCantidadMaxima !== item.nCantidadMinima ? ` y máximo ${item.nCantidadMaxima}` : ''}.`}
											</Typography>
										)}
									</Grid>
									{item.jModificadores.map((modificador, e) => {
										const checked = item.bOpcionMultiple ? modificador.checked : item.checked ? item.checked === modificador.nOpcion && modificador.nTipoModificador === item.checkedTipoModificador : false;
										return (
											<ItemRadio checked={checked} checkbox={item.bOpcionMultiple || isGreen} key={e} onClick={() => handleCheckModificadores(i, modificador.nOpcion, item.nTipoModificador, item.bOpcionMultiple, e)}>
												<Grid container item flex alignItems="center">
													{modificador.cImagenUrl && <CardMedia alt={modificador.cDescripcion} height="50px" maxwidth="50px" src={getCdnUrl(modificador.cImagenUrl)} />}
													<Grid container direction="column" padding="0 0 0 10px">
														<Typography variant="body1" className="text">
															{modificador.cDescripcion}
														</Typography>
														{(modificador?.nProductoSIP || 0) >= 0 && isGreen ? (
															<Grid item>
																{!!(modificador?.bPrecioEspecial && modificador.nPrecio_Base_Original && modificador.nProductoSIP) && (
																	<Typography padding="0 5px 0 0" variant="line">
																		{pricePrettify(modificador.nPrecio_Base_Original)}
																	</Typography>
																)}

																<Typography variant="subtitle2regular">+{(modificador?.nPrecio_Base || 0) >= 0 ? pricePrettify(modificador.nPrecio_Base || 0) : ''}</Typography>
															</Grid>
														) : null}
													</Grid>
												</Grid>

												<CheckBox
													checkedColor={item.bOpcionMultiple || isGreen ? 'checkGreen' : undefined}
													radio={!item.bOpcionMultiple}
													checked={checked}
													onClick={() => handleCheckModificadores(i, modificador.nOpcion, item.nTipoModificador, item.bOpcionMultiple, e)}></CheckBox>
											</ItemRadio>
										);
									})}
								</ConfigProductoContainer>
							);
						})}

						{!!producto.bEdadCumpleanios && (
							<ConfigProductoContainer margin="0px" padding="25px 25px 0 25px">
								{obligatorioTag}
								<Title variant="h6" color="darkGrey" padding="0 0 10px 0">
									Edad:
								</Title>
								<Grid container>
									<ProductTextField removeLoading={loadingRemove} handleCantidad={handleEdad} handleMinus={handleEdadMinus} min={0} handlePlus={handleEdadPlus} cantidad={edad} className={''}></ProductTextField>
								</Grid>
							</ConfigProductoContainer>
						)}

						{!!producto.bNombreCumpleanios && (
							<ConfigProductoContainer margin="0px" padding="25px 25px 0 25px">
								{obligatorioTag}
								<Title variant="h6" color="darkGrey" padding="0 0 10px 0">
									Nombre:
								</Title>
								<Grid container>
									<TextFieldComment
										value={nombreCumpleaniero}
										helperText={errorNombreCumpleaniero.helperText}
										error={errorNombreCumpleaniero.error}
										onChange={handleNombreCumpleaniero}
										inputType
										placeholder="Escribe el nombre del festejado."
									/>
								</Grid>
							</ConfigProductoContainer>
						)}

						{!!producto.bPanaBox && (
							<>
								<ConfigProductoContainer margin="0px" padding="25px 25px 0 25px">
									{obligatorioTag}
									<Title variant="h6" color="darkGrey" padding="0 0 10px 0">
										De parte de:
									</Title>
									<Grid container>
										<TextFieldComment
											value={panaBoxRemitente}
											helperText={errorPanaBoxRemitente.helperText}
											error={errorPanaBoxRemitente.error}
											onChange={handlePanaBoxRemitente}
											inputType
											placeholder="Escribe el nombre."
										/>
									</Grid>
								</ConfigProductoContainer>

								<ConfigProductoContainer margin="0px" padding="25px 25px 0 25px">
									{obligatorioTag}
									<Title variant="h6" color="darkGrey" padding="0 0 10px 0">
										Para:
									</Title>
									<Grid container>
										<TextFieldComment
											value={panaBoxDestinatario}
											helperText={errorPanaBoxDestinatario.helperText}
											error={errorPanaBoxDestinatario.error}
											onChange={handlePanaBoxDestinatario}
											inputType
											placeholder="Escribe el nombre."
										/>
									</Grid>
								</ConfigProductoContainer>
							</>
						)}

						{dataProducto.dataExtras.length > 0 && (
							<ConfigProductoContainer>
								<Title padding="5px 0 10px 0" variant="h5">
									Extras
								</Title>
								{dataProducto.dataExtras.map((item, i) => (
									<ExtrasContainer
										key={i}
										i={i}
										handleAddExtras={handleAddExtras}
										apiUrl={apiUrl}
										cImagenUrl={item.cImagenUrl}
										cDescripcion={item.cDescripcion}
										nPrecio={item.nPrecio_Base}
										cDetalle={item.cDetalle}
										nCantidad={item.nCantidad}
										item={item}
									/>
								))}
							</ConfigProductoContainer>
						)}

						{dataProducto.dataComplementos.length > 0 && !carritoItem && (
							<ConfigProductoContainer>
								<Title padding="5px 0 10px 0" variant="h5">
									Complementa tu orden
								</Title>
								{dataProducto.dataComplementos.map((item, i) => (
									<ExtrasContainer key={i} i={i} handleAddExtras={handleAddComplementos} apiUrl={apiUrl} cImagenUrl={item.cImagenUrl} cDescripcion={item.cDetalle} nPrecio={item.nPrecio_Base} nCantidad={item.nCantidad} item={item} />
								))}
							</ConfigProductoContainer>
						)}

						{!!producto.bFelicidades && (
							<ConfigProductoContainer margin="0px" padding="25px 25px 0 25px">
								<Title variant="h6" color="darkGrey" padding="0 0 5px 0">
									¿Deseas agregar felicidades?
								</Title>
								<Grid container justifyContent="center">
									<CardMedia alt="Agregar Felicitación" staticImg maxwidth="200px" height="150px" src={nombreTarjeta} />
								</Grid>
								<TextFieldComment
									value={nombreDelFestejado}
									onChange={handleNombreDelFestejado}
									inputType
									placeholder="Escribe el nombre del festejado."
									helperText={errorNombreDelFestejado.helperText}
									error={errorNombreDelFestejado.error}
								/>
							</ConfigProductoContainer>
						)}

						<ConfigProductoContainer padding={producto.bFelicidades ? '25px 25px 10px 25px' : undefined}>
							<Title variant="h6" color="darkGrey" padding="0 0 5px 0">
								Comentarios
							</Title>
							<TextFieldComment
								value={comentario}
								maxLength={200}
								helperText={errorComentario.helperText}
								error={errorComentario.error}
								onChange={handleComentario}
								placeholder="Añade instrucciones. Haremos lo posible por cumplirlas."
							/>
							<Typography padding="8px 0 0 0" variant="body2">
								Si se requiere un cambio en el precio, omitiremos los comentarios.
							</Typography>
						</ConfigProductoContainer>
					</ScrollContainer>

					{xsCarrito && footerItem}
				</Grid>
			</DetalleContainer>

			{!xsCarrito && footerItem}
		</>
	);
};
export default DetalleProducto;
