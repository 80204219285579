import React from 'react';
import DatePickerSelect from '~/components/DatePickerSelect';
import TextFieldSelect from '~/components/TextFieldSelect';
import Typography from '~/components/Typography';
import { DataCarritoTypes, ErrorTextFieldType, SelectItemsTypes } from '~/interfaces';
type Props = {
	fechaRecoleccion: string | null,
	handleFechaRecoleccion: (e: string) => void,
	horas: {
		nValor: number | string;
		cValor: string;
	}[] | undefined,
	carrito: DataCarritoTypes | undefined,
	horaRecoleccion: SelectItemsTypes | null,
	errorDate: ErrorTextFieldType,
	isCheckout?: boolean,
	handleHoraRecoleccion: (value: SelectItemsTypes) => void,
};
const ProgramarEnvio : React.FC<Props> = ({ 
	fechaRecoleccion,
	handleFechaRecoleccion,
	errorDate,
	horas,
	isCheckout,
	carrito,
	horaRecoleccion,
	handleHoraRecoleccion,
} : Props) => {
	return (
		<>
			{
				isCheckout && (!fechaRecoleccion) && <Typography padding='0 0 3px 0' color='red'>Por favor completa todos los datos para poder continuar con tu compra. </Typography>
			}

			<DatePickerSelect 
				error={(errorDate.error) || (fechaRecoleccion === null && isCheckout)}
				helperText={(errorDate.helperText) || (fechaRecoleccion === null && isCheckout? ('Campo requerido') : carrito?.nRangoLibreFechasDisponibles === false? 'Tu carrito contiene productos con fechas de envío limitadas.' : '')}
				nRangoLibreFechasDisponibles={carrito?.nRangoLibreFechasDisponibles} 
				dFechasAPartirDe={carrito?.dFechasAPartirDe} 
				dFechasDisponibles={carrito?.dFechasDisponibles} 
				value={fechaRecoleccion} 
				label="Elige la fecha" 
				fullWidth
				handleCLick={handleFechaRecoleccion} 
			/>

			<TextFieldSelect 
				fullWidth
				disabled={!fechaRecoleccion}
				helperText={ horas === undefined ? 'Cargando horas disponibles...' : horas.length === 0 ? 'No se han encontrado horas disponibles, intente con otra fecha.' : horaRecoleccion === null && isCheckout? ('Campo requerido') : ''}
				padding='10px 0 0 0'
				label='Elige la hora'
				id='horasSelect'
				items={horas || []} 
				error={horaRecoleccion === null && isCheckout && horas? true : (horas && horas.length === 0)}
				value={horaRecoleccion || undefined}
				onChange={handleHoraRecoleccion}
			></TextFieldSelect>
		</>
	);
};
export default (ProgramarEnvio);