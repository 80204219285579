import  {
	useState,
	useEffect,
} from 'react';

const useTimer = (counterTimer: number, handleReset?: () => void) => {
	const [progress, setProgress] = useState(counterTimer);

	useEffect(() => {
		if(progress > 0) {
			setTimeout(function() {
				setProgress(progress - 1);
			},800);
		} else {
			if(handleReset){
				handleReset();
			}
		}
	}, [progress]);

	return {
		progress,
	};
};

export default (useTimer);