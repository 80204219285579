import { initialStateType } from '~/interfaces';
import { handleGoToFacturacion, handleGoToProveedores } from '~/utils';

export const EMPTY_TITLES = {
	title1: 'Sin resultados.',
	title2: 'Pastel no encontrado.',
	title3: 'Catálogo de categorías vacío.',
	subtitle1: 'No se ha añadido ningún pastel con esas características al listado. Te invitamos a que sigas navegando en nuestro extenso menú de pasteles y agregues aquellos que te gusten, gracias por tu preferencia.',
	subtitle2: 'Te invitamos a que sigas navegando en nuestro extenso menú de pasteles y agregues aquellos que te gusten, gracias por tu preferencia.',

	subtitle3: '¡Lo sentimos!, tu búsqueda no ha dado resultados. Por favor, regresa al menú y explora nuestras categorías.',
	subtitle4: '¡Lo sentimos!, actualmente no contamos con dinámicas disponibles. Te invitamos a que te mantengas al tanto de nuestras dinámicas a traves de nuestras redes sociales.',
};
export const MENU_ITEMS = [
	{
		id: 1,
		nombre: 'Inicio',
		goto: '/',
	},
	{
		id: 2,
		nombre: 'Pastelería',
		goto: '/tienda/',
		/* 		categorias: [
			{
				id: 13,
				nombre: 'Pasteles especiales',
				subcategorias: [
					{
						id: 27,
						nombre: 'Año nuevo',
					},
					{
						id: 28,
						nombre: 'Cumpleaños',
					},
					{
						id: 29,
						nombre: 'Día de las madres',
					},
					{
						id: 30,
						nombre: 'Especialidades Helados',
					},
					{
						id: 31,
						nombre: 'Graduación',
					},
					{
						id: 32,
						nombre: 'Halloween',
					},
					{
						id: 33,
						nombre: 'Infantiles',
					},
					{
						id: 34,
						nombre: 'Navidad',
					},
					{
						id: 35,
						nombre: 'Patrios',
					},
					{
						id: 36,
						nombre: 'San Valentín',
					},
					{
						id: 37,
						nombre: 'Sociales',
					},
					{
						id: 38,
						nombre: 'Tendencias',
					},
				],
			},
			{
				id: 14,
				nombre: 'Más productos',
				subcategorias: [
					{
						id: 100,
						nombre: 'Pasteles Helados',
					},
					{
						id: 101,
						nombre: 'Complementos',
					},
					{
						id: 102,
						nombre: 'Charolas',
					},
					{
						id: 103,
						nombre: 'La Cocina Del Pana',
					},
					{
						id: 104,
						nombre: 'Pecaditos',
					},
					{
						id: 105,
						nombre: 'Panadería',
					},
				]
			}
		], */
	},
	{
		id: 3,
		nombre: 'Restaurante',
		goto: '/menu-restaurant',
		/* categorias: [
			{
				id: 51,
				nombre: 'Restaurante',
				subcategorias: [
					{
						id: 502,
						nombre: 'Panabox Desayuno'
					},
					{
						id: 503,
						nombre: 'Panabox Comida'
					},
					{
						id: 503,
						nombre: 'Souvenirs'
					},
          
				]
			},
			{
				id: 52,
				nombre: 'Desayunos',
				subcategorias: [
					{
						id: 61,
						nombre: 'Menú kids'
					},
					{
						id: 62,
						nombre: 'Lo nuevo'
					},
					{
						id: 63,
						nombre: 'Jugos, Frutas y Cereales'
					},
					{
						id: 64,
						nombre: 'Sopas y cremas'
					},
					{
						id: 65,
						nombre: 'Desayunos Dulces'
					},
					{
						id: 66,
						nombre: 'Desayunos y Huevo'
					},
					{
						id: 67,
						nombre: 'Combinaciones'
					},
					{
						id: 68,
						nombre: 'Bebidas'
					},
					{
						id: 69,
						nombre: 'Postres'
					},
				],
			},
			{
				id: 53,
				nombre: 'Comidas',
				subcategorias: [
					{
						id: 601,
						nombre: 'Menú kids',
					},
					{
						id: 602,
						nombre: 'Lo nuevo',
					},
					{
						id: 603,
						nombre: 'Entradas',
					},
					{
						id: 604,
						nombre: 'Sopas y cremas',
					},
					{
						id: 605,
						nombre: 'Sánwiches y Hamburguesas',
					},
					{
						id: 606,
						nombre: 'Con sabor de la carretera',
					},
					{
						id: 607,
						nombre: 'De la vaquita',
					},
					{
						id: 608,
						nombre: 'Saludable',
					},
					{
						id: 609,
						nombre: 'Antojitos Mexicanos',
					},
					{
						id: 610,
						nombre: 'De bebidas',
					},{
						id: 611,
						nombre: 'Del mar',
					},
          
				]
			},
		] */
	},
	{
		id: 4,
		goto: '/sucursales',
		nombre: 'Sucursales',
	},
	{
		id: 5,
		nombre: 'Facturación',
		goto: '/facturacion',
	},
];

export const SERVICIOS = [
	{
		id: 1,
		nombre: 'Facturación',
		onclick: handleGoToFacturacion,
	},
	{
		id: 2,
		nombre: 'Proveedores',
		onclick: handleGoToProveedores,
	},
	{
		id: 3,
		goto: '/ayuda',
		nombre: 'Obtener ayuda',
	},
];

export const COMPANIA = [
	{
		id: 2,
		goto: '/trabajo',
		nombre: 'Trabajo',
	},
	{
		id: 3,
		goto: '/sucursales',
		nombre: 'Sucursales',
	},
];

export const BREADCRUMBS = {
	tienda: [
		{
			id: 1,
			nombre: 'Panamá',
			path: '/'
		},
		{
			id: 2,
			nombre: 'Productos',
			active: true,
		},
	]
};

export const initialState: initialStateType = {
	token: null,
	mcadd: null,
	userInfo: null
};

export const statusDialogPropsInitialValue = {
	open: false,
	error: false,
	helperText: '',
};

export const RECOGER_STEPS = [
	{
		cNombre: 'Selecciona la ciudad',
		nValor: 1
	},
	{
		cNombre: 'Selecciona la sucursal',
		nValor: 2
	},
	{
		cNombre: 'Selecciona la fecha y hora',
		nValor: 3
	},
];

export const PERFIL_MENU = [
	{
		cNombre: 'Ajustes de cuenta',
		nValor: 1,
		path: '/ajustes-de-cuenta'
	},
	{
		cNombre: 'Mis direcciones',
		nValor: 2,
		path: '/mis-direcciones'
	},
	{
		cNombre: 'Cartera',
		nValor: 3,
		path: '/metodos-de-pago'
	},
	{
		cNombre: 'Últimas ordenes',
		nValor: 4,
		path: '/ultimas-ordenes'
	},
];

export const SUCURSALES_MENU = [
	{
		cMunicipio: 'Culiacán',
		padding: '0 8px 0 0',
		borderRight: true,
		justify: 'flex-end',
		id: 1,
	},
	{
		cMunicipio: 'Mazatlán',
		padding: '0 8px 0 8px',
		justify: 'center',
		id: 2,
	},
	{
		cMunicipio: 'Los Mochis',
		padding: '0 0 0 8px',
		borderLeft: true,
		id: 3
	}
];