import React from 'react';
import Grid from '../Grid';
import { LoadingRinContainer } from './styled';
type Props = {
	color?: string,
	size?: string
};

const LoadingRing : React.FC<Props>= ({color, size} : Props) => {
	return (
		<Grid width='100%' container justifyContent='center'>
			<LoadingRinContainer size={size} color={color}>
				<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
			</LoadingRinContainer>
		</Grid>
	);
};
export default (LoadingRing);