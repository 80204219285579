import styled from 'styled-components';
import { getIsTouchDevice } from '~/utils';
import { ModalProps } from '../CarritoDrawer/styled';

export interface SideBarContainerProps {
  profile?: boolean |string;
  showSideBar?: boolean;
}

export const SideBarContainer = styled.div<SideBarContainerProps>`
    border-bottom: 5px solid ${props => props.theme.colors.primary};
    display:block;
    width: 0px;

    width: ${props => props.showSideBar ?  ('438px') : '0px'};
    transition: all 0.3s ease-in-out;


    left: 0;
    bottom: 0;
    display: flex;
    z-index: 1010;
    flex-direction: column;
    overflow-x: hidden;
    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 438px;
        padding: 1rem 2rem 2rem 2rem;
        box-sizing: border-box;

        @media (max-width: 824px) {
            padding: 1rem 11px 2rem 22px;
            min-width: 300px;
        }
    }
    box-sizing: border-box;

    @media (max-width: 824px) {
        width: ${props => props.showSideBar ?  ('100%') : '0px'};
        max-width: 438px;

    }

    justify-content: flex-start;
    .body-side-bar {
      max-height: 100%;
      overflow-y: auto;
      height: fit-content;
      flex: auto;
      ${(props) => {
		if(getIsTouchDevice()){
			return null;
		} else {
			return `
      ::-webkit-scrollbar {
          -webkit-appearance: none;
      }
          
      ::-webkit-scrollbar:vertical {
          width: 8px;
      }
          
      ::-webkit-scrollbar:horizontal {
          height: 12px !important;
      }
          
      ::-webkit-scrollbar-thumb {
          background-color: ${props.theme.colors.grapeFruitDark};
          border: 0px;

      }
          
      ::-webkit-scrollbar-track {
          border-radius: 0px;
          background-color: #ffe3e0;
      }
      `;
		}
	}}
      
    }

    position: fixed;
    top: 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: ${props => props.theme.colors.white};
`;

export const TopContainer = styled.div`
    background: ${props => props.theme.colors.peach};
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    height: 80px;
`;


export const Modal = styled.div<ModalProps>`
  visibility: hidden;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(77, 77, 77, .7);
  z-index: 1001;
  ${props => props.open ? `
    visibility: visible;
  ` : null}
`;