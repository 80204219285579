import styled, { css } from 'styled-components';

export interface LinkSpanProps {
  regular?: boolean;
}


export const LinkSpan = styled.span<LinkSpanProps>`
    ${props => css`
        font-family: ${props.theme.text.font.primary};
        font-size: ${props.theme.text.size.subtitle2};
        color: ${props.theme.colors[props.regular ? 'fontGrey' : 'black']};
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            color: ${props.theme.colors.primary};

        }

    `}
`;

export default LinkSpan;