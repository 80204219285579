import React, {useEffect} from 'react';
import CloseButton from '../CloseButton';
import Grid from '../Grid';
import {Modal, ModalBodyContainer, ModalContainer} from './styled';
import { getIsTouchDevice } from '~/utils';
type Props = {
  children: JSX.Element | JSX.Element[],
  title?: JSX.Element | JSX.Element[],
  action?: JSX.Element | JSX.Element[],
  style?: React.CSSProperties,
  open?: boolean,
  handleClose: () => void,
  padding?: string,
  height?: string,
  close?: boolean,
  width?: string,
  responsiveFullSize?: boolean,
  maxWidth?: string
};
const ModalComponent : React.FC<Props> = ({ handleClose, children, open,padding ,height, close, title, action, width, maxWidth, responsiveFullSize } : Props) => {
	const scrollMood = getIsTouchDevice();

	useEffect(() => {
		const body = document.body;
		if(open){
			if(scrollMood){
				// When the modal is shown, we want a fixed body
				body.style.overflow = 'hidden';
				body.style.position = 'relative';
				body.style.height = '100vh';
			} else {
				body.style.height = '100vh';
				body.style.overflowY = 'hidden';
			}
			
		} else {
			if(scrollMood){
				// When the modal is hidden...
				body.style.height = 'auto';
				body.style.position = '';
				body.style.overflow = 'auto';

			} else {
				body.style.height = 'auto';
				body.style.overflowY = 'auto';
			}
		}
	}, [open]);
    
	return (
		<Modal onMouseDown={handleClose} open={open}>
			<ModalContainer 
				responsiveFullSize={responsiveFullSize} 
				maxWidth={maxWidth} 
				width={width} 
				onScroll={(e)=>{
					e.stopPropagation();
				}}
				onMouseDown={(e)=>{
					e.stopPropagation();
				}} 
				height={height}
			>
				{
					close ? <Grid padding='10px' container>
						<CloseButton onClick={handleClose} />
					</Grid> : null
				}
				<div >
					{title}
				</div>
				{
					height || responsiveFullSize ? <ModalBodyContainer  padding={padding}>
						{children}
					</ModalBodyContainer> : <Grid flexWrap="nowrap"  maxheight="70vh" container direction='column' padding={padding}>{children}</Grid>
				}
				
				<div >
					{action}
				</div>
			</ModalContainer>
			
		</Modal>
	);
};
export default (ModalComponent);