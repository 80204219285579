import { ArrowBackIcon, ArrowFowardIcon } from '~/assets/icons';
import Grid from '~/components/Grid';
import moment from 'moment';
import 'moment/locale/es';
import IconButton from '~/components/IconButton';
import Typography from '~/components/Typography';
import { ButtonDay, RowList } from './styled';
import { LIMITS } from './hooks';

interface props {
	handleBackYear: () => void;
	handleNextYear: () => void;
	selectedYear: string;
	handleBackStepper: () => void;
	handleClickMonth: (month: number) => void;
	value: string | null;
}

export const YearPicker = ({ handleBackStepper, selectedYear, handleBackYear, handleNextYear, handleClickMonth, value }: props) => {
	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid
					container
					alignItems="center"
					cursor="pointer"
					onClick={(e) => {
						e.stopPropagation();
						handleBackStepper();
					}}>
					<Typography variant="subtitle1bold" transform="capitalize">
						<strong>{parseInt(selectedYear)}</strong>
					</Typography>
					<ArrowFowardIcon style={{ rotate: '90deg', scale: '0.7' }} />
				</Grid>

				<Grid container>
					<IconButton onClick={() => handleBackYear()}>
						<ArrowBackIcon />
					</IconButton>

					<IconButton onClick={() => handleNextYear()}>
						<ArrowFowardIcon />
					</IconButton>
				</Grid>
			</Grid>

			<Grid className="short_animated zoomIn" margin="15px 0px 0px 0px" spacing="12px" container direction="column">
				<>
					{[...Array(3)].map((_, row) => (
						<RowList container justifyContent="space-between" spacing={'5px'} direction="row" key={`RowList${row}`}>
							{[...Array(4)].map((_, col) => {
								const itemCol = row * 4 + col;

								const yearLimitBegin = parseInt(moment(LIMITS.BEGIN).format('YYYY'));
								const yearLimitEnd = parseInt(moment(LIMITS.END).format('YYYY'));

								const monthLimitBegin = parseInt(moment(LIMITS.BEGIN).format('M'));
								const monthLimitEnd = parseInt(moment(LIMITS.END).format('M'));

								const year = parseInt(selectedYear);

								const isBeforeLimits = year === yearLimitBegin && itemCol < monthLimitBegin - 1;
								const isAfterLimits = year === yearLimitEnd && itemCol > monthLimitEnd - 1;

								const isSelected = moment().year(parseInt(selectedYear)).month(itemCol).format('YYYY-MM') === moment(value).format('YYYY-MM');

								return (
									isBeforeLimits || isAfterLimits ? (
										<ButtonDay 
											key={`Button${itemCol}${Math.random()}`} 
											disabled
										>
											{moment().month(itemCol).format('MMM').split('.')[0].toUpperCase()}
										</ButtonDay>
									) : (
										<ButtonDay 
											key={`Button${itemCol}${Math.random()}`}
											isSelected={isSelected}
											onClick={(e) => {
												e.stopPropagation();
												handleClickMonth(itemCol);
											}}
										>
											{moment().month(itemCol).format('MMM').split('.')[0].toUpperCase()}
										</ButtonDay>
									)
								);
							})}
						</RowList>
					))}
				</>
			</Grid>
		</>
	);
};
