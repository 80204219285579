import { useEffect, useState } from 'react';
import { DataCarritoTypes } from '~/interfaces';

const useCarritoDrawer = (currentCarrito: DataCarritoTypes | undefined,) => {
	const [isShowing, setIsShowing] = useState(false);
	const [currentDrawerCarrito, setCurrentDrawerCarrito] = useState(currentCarrito?.nValor || undefined);
	const handleIsShowing = () => {
		setIsShowing(!open);
	};

	useEffect(() => {
		setIsShowing(!!currentCarrito);
		setCurrentDrawerCarrito(currentCarrito?.nValor);

	}, [currentCarrito?.nValor]);

	const handleClickMenuCarrito = (carrito: number) => {
		setCurrentDrawerCarrito(carrito);
		setIsShowing(true);
    
	};

	const [loadingLeftSide, setLoadingLeftSide] = useState<number | null>(null);
	const [loadingRightSide, setLoadingRightSide] = useState<number | null>(null);

	const handleloadingLeftSide = (value: number) => {
		setLoadingLeftSide(value);
	};

	const handleStroploadingLeftSide = () => {
		setLoadingLeftSide(null);
	};

	const handleloadingRightSide = (value: number) => {
		setLoadingRightSide(value);
	};

	const handleStroploadingRightSide = () => {
		setLoadingRightSide(null);
	};


	return {
		handleIsShowing,
		isShowing,
		currentDrawerCarrito,
		handleClickMenuCarrito,
		loadingLeftSide,
		loadingRightSide,
		handleloadingLeftSide,
		handleStroploadingLeftSide,
		handleloadingRightSide,
		handleStroploadingRightSide,
        
	};
};

export default useCarritoDrawer;