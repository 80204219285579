import React from 'react';
import { CancelIcon } from '~/assets/icons';
import IconButton from '../IconButton';
export type IconButtonProps = {
    style?: React.CSSProperties,
    styleIcon?: React.CSSProperties,
    onClick?: VoidFunction,
    className?: string,
    bgColor?: string,
    color?: string,
  };
const CloseButton : React.FC<IconButtonProps> = (props : IconButtonProps) => {
	return (
		<IconButton {...props}>
			<CancelIcon color={props.color} style={props.styleIcon}></CancelIcon>
		</IconButton>
	);
};
export default (CloseButton);