import { Payment } from '@mercadopago/sdk-react';
import { SwipeableHandlers } from 'react-swipeable';

export type FuncChangeEventType = (e: React.ChangeEvent < HTMLInputElement >) => void
export type FuncChangeSelectType = (e: SelectItemsTypes) => void

export type VoidFunctionType = () => void;
export type UserInfoType = {
	cNombre?: string,
	cApellido_Paterno?: string,
	cApellido_Materno?: string,
	nGenero?: number | string | null,
	cCorreo?: string,
	cNumero_Celular?: string,
	nEstatus?: number,
	dFecha_Nacimiento?: string,
	cImg_Perfil?: string,
	invitado?: boolean,
	nut?: number, // tipo usuario (1=normal, 2=byGoogle, 3=byFacebook)
	nombre?: string,
	tel?: string
}
export type DireccionType = {
	nDireccion?: number,
	cCodigoPostal?: string,
	nColonia?: string | number,
	cColonia?: string,
	cCalle?: string,
	cNumeroExterior?: string,
	cNumeroInterior?: string | null,
	cEntreCalle?: string,
	nTipoDomicilio?: string | number,
	cTipoDomicilio?: string,
	cIndicadoresAdicionales?: string,
	cMunicipio?: string,
	cEstado?: string,
	
}

export type RecoleccionType = {
	nRecoleccion?: number,
	cSucursal?: string,
	nSucursal?: number,
	cCiudad?: string,
	nCiudad?: number,
}
export type ProgramarType = {
	fechaRecoleccion?: string,
	cHoraRecoleccion?: string
	nHoraRecoleccion?: number
}

export type DetalleEnvioType = {
	cCalle:          string,
    cColonia:        string,
    cEntreCalle:     string,
    cMunicipio:      string,
    cNumeroExterior: string,
    cNumeroInterior: string,
    cReferencias:    string,
    cSucursal:       string,
    done:            boolean,
    error:           string,
    nColonia:        number,
    nDireccion:      number,
    nMunicipio:      number,
    cEstado:         string,
    nEstado:         number,
    nSucursal:       number,
    cTipoDomicilio:  number,
    nTipoEntrega:    number,
    dFechaAgendada:  string,
    cHoraAgendada:   string,
    cValorHoraAgendada: string,
	nTipoDomicilio: number,
    nNivelTienda?: number,
	bHoraPendiente?: boolean,
	nivelesTiendaId?: number,
}


export type ErrorTextFieldType = {
	error: boolean,
	check: boolean,
	helperText: string,
}
export type HeadersApiType = {
	Authorization: string,
	macAddress: string,
	'Content-Type': string,
}
export type PastelesProps = {
	bFavoritos: boolean,
	cFotoAlbum: string,
	nFotoAlbum: number,
	checked?: number,
	nOrden: number,
	nPersonasMaximo: number,
	nPersonasMinimo: number,
	nPrecioMaximo: number,
	nPrecioMinimo: number,
	oImagen: string
}

export type TiendaContextProps = {
	token: null | string,
	currentCategoria: string | undefined,
	setCurrentCategoria: React.Dispatch<React.SetStateAction<string | undefined>>,
	macAddress: null | string,
	userInfo: null | UserInfoType,
	handleUserInfo: (tokenValue: string,macAddressValue: string,userInfoValue: UserInfoType) => void,
	headersApi: HeadersApiType,
	headersApiKey: HeadersApiType,
	handleLogout: (onFinish?: VoidFunction) => void,
	handleActiveStatus: (newStatus?: number) => void,
	yPosition: null | number,
	active: number,
	handleactive: (value: number) => void,
	handleNext: () => void,
	setActive:  React.Dispatch<React.SetStateAction<number>>,
	handleBack: () => void,
	handleMousePos: () => void,
	setyPosition: (value: React.SetStateAction<number | null>) => void,
	menuId: number | undefined,
	setMenuId: React.Dispatch<React.SetStateAction<number | undefined>>,
	// carrito
	dataCarrito: undefined | DataCarritoTypes[],
	setDataCarrito: React.Dispatch<React.SetStateAction<DataCarritoTypes[] | undefined>>,
	handleDetalleEnvio:  (value: DetalleEnvioType | null) => void,
	detalleEnvio: DetalleEnvioType | null,
	initialLoading: boolean,
	handleDoneInitialLoading: VoidFunction,

	openWarningUserModal: boolean,
	handleOpenWarningUserModal: (value: boolean)=>void,
};

export type ContextProps = {
	children: JSX.Element | JSX.Element[]
}

export type CarrouselProps = {
	nImagen: number,
	cImagen: string,
	oImagen: string,
}

export type dataTiposPedidosProps = {
	id: number,
	nombre: string,
	path: string,
}

export type CategoriasSubMenuProps = {
	nCategoria: number,
    nPadre?: number,
	nTipoPlataforma?: number,
    cImagenUrl?: string,
    nNivelTienda?: number,
    nHorario?: string,
    cDescripcion?: string,
}
export type CategoriasProps = {
	nCategoria?: number,
    id?: number,
    nombre?: string,
    path?: number,
	nTipoPlataforma?: number,
    cImagenUrl?: string,
    nNivelTienda?: number,
    nHorario?: string,
    cDescripcion?: string,
	subCategorias?: CategoriasSubMenuProps[]
}

export type ProductosProps = {
	nVProducto: number,
	nProductoSIP: null | number;
	cDetalle: string,
	bDisponible: number,
    cDescripcion: string,
	nNuevoPrecio: number | undefined | null,
	nPrecioMaximo: number | null,
    cImagenUrl: string,
	nDiasAnticipacion?: number,
	nPrecio_Base: number | null,
	nPrecio_Maximo: number | null,
	bFelicidades?: boolean | null,
	bFavorito?: boolean,
	nCategoriaComplemento: number,
	cImagenUrlCategoriaComplemento: string,
	bPopular?: boolean,
	bPanaBox?: boolean,
	bAnticipacion?: number,
	bEdadCumpleanios?: number,
	bNombreCumpleanios?: number,
	bNuevo?: number,
	bPrecioEspecial?: number,
	bNuevoPrecioEspecial?: number,
	bNuevaDisponibilidad?: boolean,
	cEtiquetaPromocion?: string,
	nNuevo_Precio_Base_Original?: null | number
	nPrecio_Base_Original?: null | number
}

export type ProductosExtrasProps = {
	cDescripcion: string,
	cDetalle: string,
	nCantidad?: number;
	cImagenUrl?: string,
	nProductoSIP: number,
	bPrecioEspecial?: number,
	nPrecio_Base_Original?: null | number,
	nPrecio_Base: number,
	nExtra: number
}

export type DataComplementosProps = {
	cDetalle: string,
	cImagenUrl: string,
	bPrecioEspecial?: number,
	nPrecio_Base_Original?: null | number,
	nPrecio_Base: number,
	nProductoSIP: number,
	nVProducto: number,
	nCantidad?: number
}

// config -----
export interface ConfiguracionProducto {
    dataModificadoresProducto: DataModificadoresProducto[];
    dataVariantesProducto: DataVariantesProducto[];
    dataProductoVariantes: { [key: string]: {
		nPrecio_Base: number,
		bPrecioEspecial?: number,
		nPrecio_Base_Original?: null | number,
		nProductoSIP: number
		bDisponible: number
	} };
    producto: ProductosProps;
    dataExtras: ProductosExtrasProps[];
    nivelProducto: number;
    dataComplementos: DataComplementosProps[];
}

export interface DataModificadoresProducto {
    nTipoModificador: number;
    cDescripcionModificador: string;
    bOpcionMultiple: boolean;
    bObligatorio: boolean;
	checked?: number;
	checkedTipoModificador?: number;
	nCantidadMaxima: number;
	nCantidadMinima: number;
    jModificadores: JModificadores[];
}

export interface JModificadores {
    nTipoModificador: number;
    checked?: boolean;
    nOpcion: number;
	nProductoSIP?: number;
	bPrecioEspecial?: number;
	nPrecio_Base?: number;
	nPrecio_Base_Original?: number;
    cDescripcion: string;
    cImagenUrl?: string;
}

export interface DataVariantesProducto {
    nTipoVariante: number;
    cDescripcionVariante: string;
    checked?: number;
	checkedTipoVariante?: number;
    jVariantes:              JVariante[];
}

export interface JVariante {
    nTipoVariante: number;
    nOpcion:       number;
    cDescripcion:  string;
}
export type CategoriaProps = {
	nCategoria?: number,
    cDescripcion?: string,
}

export interface I3DinitializationStatus {
	paymentId: string,
    additionalInfo: {
		externalResourceURL: string,
		creq: string,
	},
}

export interface IConfig {
    paymentMethods?: {
		minInstallments: number,
		maxInstallments: number,
	},
}

// --------------

export type PaginadorProps = {
	nPaginaActual: number,
	nProductosPorPagina: number,
	nTotalPaginas: number,
}

export type initialStateType = {
	token: null | string,
	userInfo: null | UserInfoType,
	mcadd: null | string,
}


export type CatalogosTiposDatosProps = {
	cLlave?: string, nValor: number, cValor: string
};

export type DataCiudadesTypes = {
	nMunicipio: number, cMunicipio: string
};

export type SelectItemsTypes = {
	nValor: number | string,
	cValor: string,
	cLlave?: string,

};

export type CardFormDataType = {
	paymentMethodId: string,
	issuerId: string,
	cardholderEmail: string,
	amount: string,
	token: string,
	installments: number,
	identificationNumber?: string,
	identificationType?: string,
}

export type PaymentProps = React.ComponentProps<typeof Payment>;

export type mpDataType = {
	id: string,
    tarjetas: string[]
}


export type ObtenerBusquedasPopularesTypes = {
    nNivelTienda?: number;
    cNivelTienda?: string;
    cImagenUrl?: string;
    JCategorias?: JCategorias[];
}

export interface JCategorias {
    nCategoria:   number;
    cDescripcion: string;
    cImagenUrl:   string;
}

export type BreadCrumbProps = {
    id: number,
    nombre: string,
    active?: boolean,
    path?: string | null,
};

//  Carrito -------

export type ExtrasTypes = {
    cDescripcion: string | null,
	nExtra: number,
    nTipoExtra: number,
    nTipoOpcionExtra: number | null,
    nCantidad?: number | null,
	nPrecio_Base?: number,
	nPrecio_Base_Total?: number | null
};
export type FechasDisponiblesTypes = {
    dFecha: string,
};
export type CarritoTypes = {
    cDescripcion: string,
    jExtras: ExtrasTypes[],
    nCantidad: number,
    nPrecio_Base: number,
    nProductoSIP: number,
    nTotal: number,
    nVProducto: number,
	nPedido: number | undefined,
    cImagenUrl: string,
    cComentarios: string,
	cFelicidades: string,
	nEdadCumpleanios: string,
	cNombreCumpleanios: string
	cParaPanaBox: string
	cDePanaBox: string
};

export type  DataCarritoTypes = {
    cValor: string,
	carrito: CarritoTypes[]
    nTotal: number,
    nTotalCantidad: number,
    nValor: number,
	cSucursal?: string,
	nDireccion?: string,
	cCalle?: string,
	nRangoLibreFechasDisponibles?: boolean,
	dFechasAPartirDe?: string,
	dFechasDisponibles?: FechasDisponiblesTypes[]
	nPrecioEnvio?: number,
	cNombreCupon?: string,
	nCupon?: number,
	doneFechasDisponibles?: boolean,
	errorFechasDisponibles?: string,
	nDescuentoCupon?: number,
	cErrorCupon?: string,
	cNumeroExterior?: string,
	doneDisponibilidadProductos?: boolean,
	cHoraEstimadaEntrega?: string,
	errorDisponibilidadProductos?: string,
	nTotalSinDescuento: number

};

// BodyModificaCarrito Types
export type  BodyModificadoresTypes = {
    nTipoModificadorSeleccionado: number,
	jModificadorSeleccionado:{
		nOpcion: number,
	}

};
export type  BodyVariantesTypes = {
	nTipoVarianteSeleccionado: number,
	jVarianteSeleccionado:{
		nOpcion: number,
	}

};
export type  BodyCarritoTypes = {
    item_general: {
		nVProducto: number,
		nProductoSIP: number
	},
	cantidad: number,
	modificadores: BodyModificadoresTypes[],
	variantes: BodyVariantesTypes[]

};

export type  BodyModificaCarritoTypes = {
    nNivelTienda: number,
	carrito: BodyCarritoTypes[]

};

export interface Colonias {
    nEstado:    number;
    cEstado:    string;
    nMunicipio: number;
    cMunicipio: string;
    jColonias:  SelectItemsTypes[];
}


export type  DireccionesTypes = {
    cCalle?: string,
	cColonia?: string,
	cEntreCalle?: string,
	cEstado?: string,
	cMunicipio?: string,
	cNumeroExterior?: string,
	cNumeroInterior?: string,
	cReferencias?: string,
	nColonia?: number,
	nDireccion?: number,
	nEstado?: number,
	nMunicipio?: number,
	cCodigo_Postal?: string,
	nTipoDomicilio?: number,
	cTipoDomicilio?: string,
	bDireccionPredeterminada?: boolean | null

};

export type  HistorialOrdenesCardTypes = {
	cRegion: string, 
	cSucursal: string, 
	cTipoEntrega: string, 
	cTipoPedido: string, 
	dFechaEntrega: string, 
	nCostoDomicilio: number, 
	nDescuentos: number, 
	nFolio: number, 
	nRegion: number, 
	cImagenUrl: string,
	nSucursal: number, 
	nTipoEntrega: number, 
	nNivelTienda: number, 
	error?: string,
	nTotal: number, 
	nTotalCantidad: number, 
	nTotalSinDescuento: number, 
	cCalle: string,
	cNumeroExterior: string,
	cColonia: string,
	cMunicipio: string,
	cEstado: string,
	carrito?: CarritoTypes[]
};

export type  OrdenDetallesTypes = {
	nMunicipio: number,
	cMunicipio: string,
	nEstado: number,
	cEstado: string,
	nColonia: number,
	cTipoPedido: string,
	cSucursal?: string,
	cFolioMostrar: string,
	cMensajeAyuda: string,
	cColonia: string,
	cCalle: string,
	cCodigo_Postal: number,
	cNumeroExterior: number,
	dFechaEntrega: string,
	nCostoDomicilio?: number,
	error?: string,
	cNumeroAyuda?: string,
	nDescuentos?: number,
	cNumeroInterior: string | null,
	nTipoEntrega: number,
	cEntreCalle: string,
	nTipoDomicilio: number,
	cReferencias: string,
	nTotal: number,
	carrito: CarritoTypes[]
};

export type StepsTypes = {
	cNombre: string,
	nValor: number
};
export type StateCarritoType = {
	disabledProps?: boolean,
	sliding: boolean,
	dir: string,
	pos: number,
}
export type CarouselProps = {
	numItems: number,
	children: JSX.Element | JSX.Element[] | any,
	disabledProps?: boolean,
	handlers: SwipeableHandlers,
	state: StateCarritoType
};

export type CarouselPropsOrder = {
	index: number,
	pos: number,
	numItems: number,
};


/* 
 @nTipoPlataforma TINYINT,
    @nNivelTienda SMALLINT,
    @nUsuario INTEGER,
    @cIpAddress NVARCHAR(100),
    @nRegion TINYINT,
    @nSucursal SMALLINT,
    @dFechaEntrega DATETIME,
    @nTipoEntrega TINYINT,
    @nEstatus TINYINT,
    @nTipoTarifaDomicilio TINYINT,
    @nCostoDomicilio DECIMAL,
    @nTotalSinDescuento DECIMAL,
    @nDescuentos DECIMAL,
    @nTotal DECIMAL
*/
export type CheckoutOrderInfoType = {
	nRegion?: number,
	sucursal?: SelectItemsTypes | null,
	dFechaEntrega?: string,
	choraEntrega?: string,
	nTipoEntrega?: number,
	direccion?: DireccionesTypes | null

}
//----

export interface ISubCategorias {
	cDescripcion: string,
	cImagenUrl: string,
	nCategoria: number,
	bAnticipacion: number,
	nPrioridad: number
}
export interface InivelesTienda {
	bActivo: boolean,
	cDescripcion: string,
	cImagenUrl: string,
	nHorario?: string,
	bHorarioDisponible: number,
	nHorarioFin: string,
	nHorarioInicio: string,
	nNivelTienda: number,
	subCategorias?: ISubCategorias[],
}

export interface IdataSeccionesInteres{
	VProductos: ProductosProps[],
	cDescripcion: string,
	cNivelTienda: string,
	nNivelTienda: number,
	nPrioridad: number,
	nTipoSeccion: number,
}

export interface IdataProductosCategoria{
	VProductos: ProductosProps[],
	cNivelTienda: string,
	nNivelTienda: number,
	cCategoria: string,
	cImagenUrl: string,
	nCategoria: number,
	nPrioridad: number,
}
export interface InivelTiendaDetallesType {
	dataSubCategorias?: InivelesTienda,
	dataSeccionesInteres: IdataSeccionesInteres[],
	dataProductosCategoria: IdataProductosCategoria[],
}

export interface ISeccion {
	VProductos: ProductosProps[],
	cDescripcion: string,
	cNivelTienda: string,
	cUrlClick: string,
	nNivelTienda: number,
	nPrioridad: number,
	nTipoSeccion: number
}

export interface IOrdenesActivas {
	nFolio: string,
	nNivelTienda: number,
	cImagenUrl: string,
	cTipoPedido: string,
	nSucursal: number,
	cSucursal: string,
	nTipoEntrega: number,
	cTipoEntrega: string,
	cColor: string,
	cFolioMostrar: string,
	error: string,
	nEstatus: number, // 2 =  bueno // 3 = Error de pago 
	dFechaEntrega: string
}

export interface JFavoritosSecciones {
    nNivelTienda: number;
    cNivelTienda: string;
    nTipoSeccion: number; 
    VProductos: ProductosProps[];
}

export interface ICupon  {
	bDescuentoEspecial?: boolean,
	cVigencia?: string,
	cRestricciones?: string,
	cRestricciones2?: string,
	cNombre?: string,
	dFecha_Inicio?: string,
	dFecha_Termina?: string,
	nCupon?: number,
	nDescuentoDinero?: number | null,
	cErrorCupon?: string,
	nMinimoDeCompra?: number,
	nPorcentaje?: number,
	nDescuentoCupon?: number,
	nRegion?: number,
	bPromocion?: boolean,
	nTopado?: number
}

export interface IBannerData {
	cDescripcion: string,
	cImagenUrl: string,
	cUrlClick: string,
	bUrlClickPestañaNueva: number,
}

export interface ISucursal {
	cDireccion: string,
	cHoraInicio: string,
	cHorarioFin: string,
	cImagenUrl: string,
	cSucursal: string,
	cTelefono: string,
	cTipoSucursal: string,
	cDireccionMaps: string,
	nSucursal: number,
	nTipoSucursal: number
}


export interface ISucursales {
	Pastelerias: ISucursal[],
	Restaurantes: ISucursal[],
}
export interface IDataSucursales {
	cMunicipio: string,
	sucursales: ISucursales
}

export interface IComplementoModalStatus{
	nCategoriaComplemento: number | null,
	cImagenUrlCategoriaComplemento: string | null,
	nNivelTienda: number | null,
	open: boolean
}

export interface INuevaOrdenModalError {
	error: string, 
	subError: string,
	nFolio: number, 
	nNivelTienda: number,
}

export type DinamicaType = {
	nDinamica: number,
	cDescripcion: string,
	cUrlBases: string,
	cUrlImagen: string,
	ticketsRedimidos?: DinamicaRedencionType[],
	nTipo: number,
	cHelpText?: string,
}

export type DinamicaRedencionType = {
	cIDTicket: string,
	dFecha_Registro: string,
	cSucursal_Descripcion: string
}

export type handleModificaCarritoType = (nTipoAccion: number, nVProducto: number, nProductoSIP: number | null, cantidad: number, dataProducto?: ConfiguracionProducto | undefined, nPedido?: number | undefined, carrito?: ExtrasTypes[] | undefined, nValor?: number | undefined, handleDone?: VoidFunction, hideAlert?: boolean, cComentarios?: string, nombreDelFestejado?: string, edad?: number | string, nombreCumpleaniero?: string, panaBoxDestinatario ?: string, panaBoxRemitente ?: string) => void


export enum PAGINAS {
	INICIO = 'Inicio',
	LOG_IN = 'Inicia Sesión',
	VERICAR_CUENTA = 'Verifica tu cuenta',
	SIGN_IN = 'Crea tu cuenta',
	SIGN_IN_DONE = 'Cuenta creada con éxito',
	REESTABLECER_CONTRASENIA = 'Restablecimiento de contraseña',
	TERMINAR_COMPRA = 'Finalizar Compra',
	DIRECCIONES = 'Mis direcciones',
	USUARIO = 'Mi usuario',
	FAVORITOS = 'Favoritos',
	AYUDA = 'Ayuda',
	PEDIDOS = ' Historial de Pedidos',
	DETALLE_PEDIDO = 'Detalle Pedido',
	CUPONES = 'Cupones y promociones',
	DINAMICAS = 'Listado de dinámicas',
	PAGE_NOT_FOUND = 'Página no encontrada',
	TRABAJO = 'Trabajo',
	CENTRO_PRIVACIDAD = 'Centro de privacidad'
}

export enum TIPO_SUCURSALES {
	PASTELERIA = 'Pastelería',
	RESTAURANTE = 'Restaurante'
}