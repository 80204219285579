import styled from 'styled-components';
import { getTextVariant } from '~/utils';

export interface Props {
    padding?: string;
    color?: string;
    margin?: string;
    pointer?: boolean;
    variant?: string;
    transform?: string;
    textDecoration?: string;
    lineHeight?: string;
    textAlign?: string;
    cursor?:string;
    userSelect?:string;
}

const Typography = styled.span<Props>`
  padding: ${props =>props.padding};
  margin: ${props =>props.margin};
  font-family: ${props =>props.theme.text.font.primary};
  color: ${props => props.theme.colors[props.color ? props.color: 'black']};
  white-space: pre-line;
  cursor: ${props =>props.pointer ? 'pointer' : null};
  text-transform: ${props =>props.transform};
  text-decoration: ${props => props.textDecoration};
  line-height: ${props =>props.lineHeight ? props.lineHeight : '1.2'};
  ${props => props?.textAlign && `text-align: ${props?.textAlign};`}
  word-break: break-word;
  ${props => props.cursor && `cursor: ${props.cursor};`}
  ${props => props.userSelect && `user-select: ${props.userSelect};`}

  ${props => getTextVariant(props)}
`;
export default Typography;