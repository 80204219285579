import styled, { css } from 'styled-components';

export interface AppbarContainerProps {
  padding?: string,
  showSideBarIcon?: boolean,
  showBack?: string | null,
  openSearch?: boolean,
  responsiveHeight?: string | null | boolean
}

export interface CardMediaContainerProps {
    xs?: string;
}

export const AppbarContainer = styled.div<AppbarContainerProps>`
    display: flex;
    position: fixed;
    box-sizing: border-box;
    @media (max-width: 650px) {
        flex-direction: column;
    }
    .top {
        padding: ${props => props.showSideBarIcon ? '10px 0 10px 0px' : '10px 20px 10px 0px'};
    }
    height: ${props => props.showSideBarIcon ? '121px' : '72px'};

    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: ${props=>props.theme.colors.white};
    box-shadow: 0px 3px 10px #0000000D;

    @media (max-width: 650px) {
        height: ${props => props.responsiveHeight === true ? '94px' : (props.responsiveHeight || (props.showBack ? '72px' : '150px'))};

    }
    .searchContainer {
        width: 100%;
        ${props => props.openSearch ? css`
            padding: 0;
        ` : css`
            padding: 0 1rem 0 1rem;
        `}
        box-sizing: border-box;
    }
`;


export const CardMediaContainer = styled.div`
    z-index: 8;
    cursor: pointer;
    width: 140px;
    display: flex;
    justify-content: center;

`;

export interface BarContainerProps {
  open?: boolean;
}

export const BarContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex: 1;
    z-index: 8;
 
`;

export const CardMedia = styled.img`
    box-sizing: border-box;
    z-index: 8;
    
`;

export interface IconsContainerProps {
  padding?: string;
}

export const IconsContainer = styled.div<IconsContainerProps>`
    display: flex ;
`;

export const ContadorCarrito = styled.div`
    background-color: ${props => props.theme.colors.yellow};
    border-radius: 33px;
    height: 15px;
    text-align: center;
    min-width: 12px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 2px 0px 2px;
`;



