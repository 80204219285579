import React from 'react';
import { CuponIcon, IndeterminadeBoxIcon, SellIcon } from '~/assets/icons';
import { ICupon } from '~/interfaces';
import CheckBox from '../CheckBox';
import CuponContainer from '../CuponContainer';
import Grid from '../Grid';
import Typography from '../Typography';
type Props = {
    handleCurrentCupon?: (value: ICupon | null) => void
    currentCupon?: ICupon | null
    disabled?: boolean,
	itemCupon: ICupon,
	color?: string,
	cuponType?: number
	
};
const Cupon : React.FC<Props> = ({ 
	handleCurrentCupon,
	currentCupon,
	disabled,
	itemCupon,
	color,
	cuponType,
} : Props) => {

	/* 	const {
		open,
		handleOpen
	} = useDialog(); */
	return (
		<CuponContainer onClick={handleCurrentCupon ? () => handleCurrentCupon(itemCupon) : undefined}color={color} disabled={disabled} margin='0 0 8px 0'>
			<Grid container alignItems='center' margin='0 0 8px 0'>
				<Grid item flex>
					<Typography variant='subtitle1bold'>{itemCupon.cNombre}</Typography>
				</Grid>
				{
					cuponType ?(cuponType === 1 ? <Grid height='35px' width='35px' margin='5px 0 0 0' container alignItems='center'><SellIcon style={{transform: 'scale(1.7)'}} color='primary'></SellIcon></Grid> : <CuponIcon style={{transform: 'scale(1)', height: '35px', width: '44px'}} ></CuponIcon>) :
						handleCurrentCupon && <>
					
							{
								disabled ? <IndeterminadeBoxIcon color='disabledTextFieldGrey'></IndeterminadeBoxIcon> : <CheckBox 
									onClick={() => handleCurrentCupon(itemCupon)}
									checked={currentCupon?.nCupon === itemCupon.nCupon}
								></CheckBox>
							}
						</>
				}
				
				
			</Grid>
			<Typography variant='body3'>
				{itemCupon.cVigencia}
			</Typography>
			<Typography variant='body2'>
				{itemCupon.cRestricciones}
			</Typography>

			<Grid margin='10px 0 0 0' container bordertop >
				<Typography padding='7px 0 0 0' variant='body2'>
					{
						itemCupon.cRestricciones2
					}
                    
				</Typography>
			</Grid>
                
		</CuponContainer>
	);
};
export default (Cupon);