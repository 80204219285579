import React from 'react';
import { Input, Label, SwitchComponent } from './styled';

type props = {
	checked: boolean
	onClick: () => void
}

const Switch = ({ checked, onClick }: props) => {
	return (
		<Label>
			<Input checked={checked} type='checkbox' onChange={onClick} />
			<SwitchComponent />
		</Label>
	);
};

export default (Switch);