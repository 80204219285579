import React from 'react';
import { CardMediaContainer } from './styled';
import { useCardMedia } from '~/hooks';
import NoImageIcon from '~/assets/icons/noImage.webp';

type Props = {
	src: string,
	height?: string,
	maxwidth?: string,
	onClick?: VoidFunction,
	minHeight?: string,
	style?: React.CSSProperties,
	alt?: string,
	borderRadius?: string,
	staticImg?: boolean,
	alignItems?: string,
	pointer?: boolean,
	disabled?: boolean
};

const CardMedia : React.FC<Props> = ({ src, height, maxwidth, onClick, minHeight, borderRadius, alt, style, staticImg, alignItems, pointer, disabled } : Props) => {
	const {
		handleError,
		handleloadImg,
		loadImg, 
		errorImg,
		handleloadError
	} = useCardMedia(src, staticImg);

	return (
		<CardMediaContainer disabled={disabled} pointer={pointer} alignItems={alignItems} style={style} minHeight={minHeight} onClick={onClick} maxwidth={maxwidth} height={height}>
			{
				(loadImg || errorImg) && <div className='skeleton'/> 
			}
			{
				loadImg === null ? (
					<div className='errorContainer'>
						<img 
							loading="lazy" 
							draggable="false" 
							alt='Imagen no encontrada' 
							onLoad={handleloadError} 
							className='noimage' 
							height="24px" 
							width='24px'
							style={{ borderRadius: borderRadius }}
							src={NoImageIcon} 
						/> 
					</div>
				) : (
					<img 
						className='img'
						src={src}
						alt={alt ? `Imágen de ${alt}` : 'Imágen'}
						draggable="false"
						loading="lazy"
						style={{ borderRadius: borderRadius }}
						onLoad={handleloadImg}
						onError={handleError}
					/>
				)
			}
			
		</CardMediaContainer>
	);
};
export default (CardMedia);