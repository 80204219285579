import styled, { css } from 'styled-components';
import Grid from '../Grid';

export interface CategoriasAppBarContainerProps {
  padding?: string;
}

export const CategoriasAppBarContainer = styled.div<CategoriasAppBarContainerProps>`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 5px 8px 5px;
    border-bottom: 1px solid ${props => props.theme.colors.borderLightGrey};
    background-color: ${props =>props.theme.colors.white};
    max-width: 100vw;
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    z-index: 99;
    @media (max-width: 650px){
      padding: 0 5px 0 5px;
      top: 94px;
    }
`;

export interface CategoriaTabProps {
  active?: boolean;
}

export const CategoriaTab = styled.div<CategoriaTabProps>`
    &:hover {
      cursor: pointer;
    }
    
    span {
      font-size: ${props => props.theme.text.size.subtitle2};
      font-weight: ${props => props.theme.text.weight.semiBold};
      font-family: ${props =>props.theme.text.font.primary};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow:hidden;

    }
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    ${props => props.active ? css`
    background-color: ${props.theme.colors.yellow};
    border: 1px solid ${props => props.theme.colors.ocher};

    ` : css`
    border: 1px solid ${props => props.theme.colors.grey198};
    
    `}

`;

export const ScrollContainer = styled(Grid)`
  position: relative;
  height: 70px;
  .container > *:not(:last-child){
    margin-right: 6px;
  }
  .container {
    overflow: auto;
    padding-right: 10px;
    padding-left: 10px;
    position: absolute;
    left: 0;
    align-items: center;
    bottom: 0;
    
    top: 0;
    right: 0;
    display: inline-flexbox;
  }
`;