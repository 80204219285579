import { useEffect, useContext, useState } from 'react';
import { CookiesContext } from '~/context/cookiesContext';
import { AlertToastContext } from '~/context/alertToastContext';
import { CardFormDataType } from '~/interfaces';

// https://www.mercadopago.com.mx/developers/es/docs/checkout-api/integration-configuration/card/integrate-via-core-methods#bookmark_inicializar_campos_de_tarjeta

// Validando compra
 declare global {
    interface Window {
      MercadoPago: any;
    }
} 
const useMercadoPago = (nTotal: number ,handleCardFormData:  (value: CardFormDataType | null) => void) => {
	const publicKeyMP = import.meta.env.VITE_PUBLIC_KEY_MP;

	

	const {showError} = useContext(AlertToastContext);

	const [errorForm, setErrorForm] = useState(false);

	const [cardTokenLoading, setCardTokenLoading] = useState(false);

	// nombres
	const [nombres, setNombres] = useState('');
	const handleNombres = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setNombres(e.target.value);
	};

	// email
	const [email, setEmail] = useState('');
	const handleEmail = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setEmail(e.target.value);
	};

	const {
		userInfo
	} = useContext(CookiesContext);
	const crearCard = () => {
		const mp = new window.MercadoPago(publicKeyMP);

		const cardForm = mp.cardForm({
			amount: nTotal.toFixed(2),
			form: {
				id: 'form-checkout',
				cardNumber: {
					id: 'form-checkout__cardNumber',
					placeholder: 'xxxx-xxxx-xxxx-xxxx',
				},
				expirationDate: {
					id: 'form-checkout__expirationDate',
					placeholder: 'MM/YY',
				},
				securityCode: {
					id: 'form-checkout__securityCode',
					placeholder: '123',
				},
				cardholderName: {
					id: 'form-checkout__cardholderName',
				},
				issuer: {
					id: 'form-checkout__issuer',
					placeholder: 'Banco emisor',
				},
				installments: {
					id: 'form-checkout__installments',
					placeholder: 'Cuotas',
				},        
				cardholderEmail: {
					id: 'form-checkout__cardholderEmail',
					placeholder: 'Email',
				},
			},
			callbacks: {
				onFormMounted: (error: any) => {
					if (error) return console.warn('Form Mounted handling error: ', error);
				},
				onSubmit: (event: any) => {
					event.preventDefault();
					setCardTokenLoading(true);
					const cardInfo = cardForm.getCardFormData();
					handleCardFormData(cardInfo);
					
				},
				onFetching: (resource: any) => {
					//cardForm.unmount();

					if(resource === 'cardToken'){
						setCardTokenLoading(true);
					}
					
				},
				onError: (err: any) => {
					//cardForm.mount();
					setCardTokenLoading(false);

					const filteredErrors = err.map((item: {message: string}) => item.message);
					if(filteredErrors?.length > 0){
						
						setErrorForm(filteredErrors?.includes('parameter cardNumber can not be null/empty') || (filteredErrors?.includes('parameter expirationMonth can not be null/empty') || filteredErrors?.includes('parameter expirationYear can not be null/empty')) || filteredErrors?.includes('parameter securityCode can not be null/empty') || filteredErrors?.includes('parameter cardholderName can not be null/empty'));

					} else {
						showError('Ha ocurrido un error, por favor, intente más tarde.');
					}


				}
			},
		});
	};

	useEffect(() => {
		const checkForm = document.getElementById('form-checkout');
		const parentElement = document.getElementById('helperFrame');

		
		if(parentElement && checkForm){
			const scriptElement = document.getElementById('mercadoPagoScript');

			if(scriptElement){
				// hola();
			} else {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = 'https://sdk.mercadopago.com/js/v2';
				script.id = 'mercadoPagoScript'; // Agregamos un ID al script
				script.addEventListener('load', crearCard); // Cuando cargue el script, se ejecutará la función addCheckout
				parentElement.appendChild(script);

			}

		}
		
			

	}, []);


	



	return {
		userInfo,
		nombres,
		cardTokenLoading,
		handleNombres,
		errorForm,
		email,
		handleEmail,
	};
};

export default useMercadoPago;

/* 


	// card
	const [card, setCard] = useState('');
	const handleCard = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setCard(e.target.value);
	};
	// fecha
	const [fecha, setFecha] = useState('');
	const handleFecha = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setFecha(e.target.value);
	};
	// cvv
	const [cvv, setCvv] = useState('');
	const handleCvv = (e: React.ChangeEvent < HTMLInputElement > ) => {
		setCvv(e.target.value);
	};
	
*/