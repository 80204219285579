import styled, { css } from 'styled-components';
import { ModalProps } from '../CarritoDrawer/styled';

export interface TextFieldProps {
  icon?: boolean;
  error?: boolean;
  check?: boolean;
  open?: boolean
}

export interface TextFieldContainerInputProps {
  icon?: boolean;
  padding?: string;
}

export const TextFieldInput = styled.input<TextFieldProps>`
    padding: 8px 12px 8px 16px;
    border: none !important;
    ::placeholder,
    ::-webkit-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-size: ${props =>props.theme.text.size.subtitle2};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};
      text-align: center;
    }
    :-ms-input-placeholder {
      font-family: ${props =>props.theme.text.font.primary};
      font-weight: ${props =>props.theme.text.weight.medium};
      color: ${props =>props.theme.colors.grey};
      text-align: center;

    }
    flex: auto;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle1};
    background-color: ${props =>props.theme.colors.greyF5};
    width: 100%;
    &:focus {
      outline: none;
    }
`;


export const InputContainer = styled.div<TextFieldProps>`
  overflow: hidden;
  display: flex;
  flex: 1;
`;

export interface TextFieldContainerInputProps {
  margin?: string;
  padding?: string;
  open?: boolean;
}


export const TextFieldContainer = styled.div<TextFieldContainerInputProps>`
    display: flex;
    flex: auto;
    box-sizing: border-box;
    flex-direction: column;
    border-radius: 4px;
    padding: ${props => props.padding};
    position: relative;
    margin: ${props => props.margin};
    ${props => props.open ? css`
      width: 100%;
    ` : css`
        width: 100%;
    `}
    justify-content: flex-start;

`;


export const TextFieldContainerInput = styled.div<TextFieldProps>`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    font-family: ${props => props.theme.text.font.primary};
    background-color: ${props => props.theme.colors.greyF5};
    height: 40px;
    box-sizing: border-box;
    border-radius: 24px;
    overflow: hidden;
    width: 100%;

    ${props => props.open ? css`
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid ${props => props.theme.colors.ocher};
      flex: 1;

    ` : css`
        transition: all 0.3s ease;

      &:hover {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid ${props => props.theme.colors.black};
      }
    `}
    padding-right: 10px;
    flex: 1;
`;

export const Modal = styled.div<ModalProps>`
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
  background: rgba(77, 77, 77, .7);
  transition: visibility 0.4s ease;
  ${props => props.open ? `
    visibility: visible;
    opacity: 1;
  ` : null}
`;

export const MenuItem = styled.div`
    border: 1px solid ${props => props.theme.colors.borderGrey};
    color: ${props => props.theme.colors.black} !important;
    padding: 10px 16px 10px 16px;
    font-family: ${props =>props.theme.text.font.primary};
    font-size: ${props => props.theme.text.size.subtitle2};
    font-weight: ${props => props.theme.text.weight.semiBold};
    margin-bottom: 13px;
    border-radius: 4px;
    cursor: pointer;
    

`;

export const MenuItemImg = styled.div`
    border: 1px solid ${props => props.theme.colors.borderGrey};
    border-radius: 8px;
    display: flex;
    width: 100%;
    max-width: 342px;
    align-items: center;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    gap: 8px;
    cursor: pointer;
    margin-bottom: 8px;

`;


export const Divider = styled.div`
    background-color: ${props => props.theme.colors.borderLightGrey};
    position: absolute;
    bottom: 12.5px;
    left: 0px;
    right: 0px;
    height: 1px;
`;