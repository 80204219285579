//colors.ts

interface IDictionary<TValue> {
  [id: string]: TValue;
}
const isDev = import.meta.env.VITE_APP_IS_DEV;

const colors: IDictionary<string> = {
	primaryHover: 'rgb(168, 19, 16)',
	subtitle: 'rgb(227, 29, 26)',
	primary80: 'rgb(204, 53, 40, 0.8)',
	primaryLigth: 'rgb(196, 78, 76)',
	primaryDark: 'rgb(170, 23, 20)',
	backgroundColor: 'rgb(255, 246, 226)',
	backgroundColorSecondary: 'rgb(253, 200, 188)',
	/* Greys  */
	// grey: 'rgb(186, 186, 186)',

	borderGrey: '#C6C6C6',
	textFieldLabel: '#000000DE',
	titleGrey: 'rgba(118, 118, 118, 1)',

	lightGrey: 'rgb(186, 186, 186)',
	borderLightGrey: '#C6C6C6',
	disabledGreyColor: 'rgb(116, 135, 153, 0.4)',
	darkGrey: 'rgb(2, 2, 2, 0.8)',
	titleDarkGrey: 'rgb(70, 58, 63)',
	darkGrey65: 'rgb(2, 2, 2, 0.65)',
	bodyGrey: 'rgb(102, 102, 102)',
	darkGrey61: 'rgb(61, 61, 61)',
	inputGrey: 'rgb(247, 247, 247)',
	menuItemBg: 'rgb(247, 247, 247)',
	disabledGrey: 'rgb(224, 224, 224)',
	disabledFontGrey: 'rgb(137, 137, 137)',
	neutralBackgroundGrey: 'rgb(244, 244, 244)',
	ratGrey: 'rgb(108, 117, 125)',
	ratGrey80: 'rgb(108, 117, 125, 0.40)',
	ratGreyHover: 'rgb(183, 183, 183)',
	disabledGreyBorder: 'rgba(121, 116, 126, 1)',

	variantHeaderGrey: 'rgb(230, 230, 230)',
	toolTipGrey: 'rgb(51, 51, 51)',
	backgroundCardGrey: '#F5F5F5',
	fontGrey: 'hsl(0, 0%, 40%)',
	circularContainerGrey: 'rgb(184, 184, 184)',

	greyCard: '#DEDEDE',
	//--

	// black: 'rgb(0, 0, 0)',
	blackT: 'rgb(0, 0, 0, 0.2)',
	blackS: 'rgb(0, 0, 0, 0.4)',
	titleBlack: 'rgb(31, 31, 31)',

	black1B: '#1B1C1D',
	
	// white: 'rgb(255, 255, 255, 1)',
	white80: 'rgb(255, 255, 255, 0.7)',
	white251: 'rgb(251, 251, 251)',
	green: 'rgb(122, 201, 124)',
	// red: 'rgb(230, 64, 59)',
	ablue: 'rgb(61, 164, 211)',
	secondary: 'rgb(255, 127, 40)',
	checkGreen: '#43B02A',
	green176:'rgb(67, 176, 42)',
	blue:'#007FFF',
	blueLink: '#3366CC',
	bgColor: isDev === '1' ? 'rgb(216, 229, 255)' : 'rgb(255, 255, 255)',

	support1: 'rgba(190, 233, 251, 1)',
	support12: 'rgba(32, 146, 195, 1)',

	orange: 'rgba(255, 135, 66, 1)',
	blackForm: 'rgb(27, 28, 29)',
	yellowCard: 'rgb(255, 181, 0)',

	// colores oficiales
	primary: isDev === '1' ? 'rgb(14, 14, 114)' : 'rgba(218, 41, 28, 1)',
	red: 'rgba(218, 41, 28, 1)',
	yellow: 'rgba(255, 218, 127, 1)',
	primaryYellow: '#FFDA00',
	ocher: 'rgba(200, 142, 0, 1)',
	grey: 'rgba(118, 118, 118, 1)',
	black: 'rgba(27, 28, 29, 1)',
	grey240: 'rgba(240, 240, 240, 1)',
	grey198: 'rgba(198, 198, 198, 1)',
	grey112: 'rgba(112, 112, 112, 1)',
	greyF5: 'rgba(245, 245, 245, 1)',
	white: 'rgba(255, 255, 255, 1)',
	grey247: 'rgba(247, 247, 247, 1)',
	grey70: '#707070',
	disabledTextFieldGrey: '#8D8D8D',
	disabledCardBg: '#F5F5F5',
	yellowCupon: '#FFFAEC',

	green80: 'rgb(67, 176, 42, 0.75)',
	yellow80: 'rgb(250, 228, 169, 0.75)',
	warningYellowBg: '#FEF0DF',
	warningYellowColor: '#8B6F44'

};

export default colors;