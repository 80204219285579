import styled from 'styled-components';

export interface CuponProps {
    ocher?: boolean;
    margin?: string;
}

export const OptionContainer = styled.button<CuponProps>`
    margin: ${props => props.margin};
    border: 1px solid ${props => props.theme.colors[props.ocher ? 'ocher' : 'borderGrey']};
    &:hover {
        cursor: pointer;
    }
    display: flex;
    box-sizing: border-box;
    background-color: transparent;
    align-items: center;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    min-height: 56px;
    width: 100%;
`;


