import styled from 'styled-components';

export interface OrdenCardProps {
  padding?: string;
}

export const OrdenCard = styled.div<OrdenCardProps>`
    display: flex;
    box-sizing: border-box;
    max-width: 341px;
    border-radius: 22px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    min-height: 116px;
    width: 100%;
    padding: 8px 16px 8px 20px;
    cursor: pointer;
`;