import { isNumber } from 'lodash';
import { useState } from 'react';

const useProductTextFieldSelect = (max: number, min: number, defaultValue?: number | string) => {
	const [cantidad, setCantidad] = useState<number | string>(defaultValue || '');

	const handleCantidad = (value: number | string) => {
		setCantidad(value && isNumber(value) ? (value < min ? min : value > max ? max : value) : '');
	};

	const  handlePlus = () => {
		if(isNumber(cantidad) && cantidad < max){
			setCantidad( cantidad + 1);
		} else {
			setCantidad(1);
		}
	};


	const handleMinus = () => {
		if(isNumber(cantidad) && cantidad > min){
			setCantidad( cantidad - 1);
		} else {
			setCantidad(min);
		}
	};

	return {
		handleCantidad,
		handlePlus,
		handleMinus,
		cantidad
	};
};

export default useProductTextFieldSelect;