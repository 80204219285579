import styled from 'styled-components';
import Grid from '../Grid';

export interface GridStyledProps {
  padding?: string;
}

export const GridStyled = styled(Grid)<GridStyledProps>`
    border: 1px solid ${props => props.theme.colors.grey198};
    border-radius:22px;
    padding:16px;
    cursor: pointer;
    margin-bottom: 8px;
`;

export const ImgStyled = styled.img`
    width:32px;
    height: 32px;
    border: 1px solid black;
    border-radius:12px;
`;